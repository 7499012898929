import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Title } from '../../components/Title';
import { TodaysMetrics } from '../../components/MetricSidebar';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import CompanyAPI from '../../api/CompanyAPI';
import PricingAPI from '../../api/PricingAPI';

class PricingGroupPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      name: '',
      order: 0,
    }

    this.save = this.save.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);

  }

  componentDidMount() {
    if (this.state.id !== 'new') {
      this.loadGroup();
    }
  }

  loadGroup() {
    PricingAPI.getGroup(this.state.id, (data) => {
      this.setState({
        name: data.name,
        order: data.order,
      });
    })
  }

  deleteGroup() {
    PricingAPI.delGroup(this.state.id, (data) => {
      this.props.history.push('/pricing');
    });
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  save() {

    let payload = {
      name: this.state.name,
      order: this.state.order,
    }

    if (this.state.id === 'new') {
      PricingAPI.addGroup(payload, (data) => {
        console.log("addGroup()", data);
        this.props.history.push('/pricing');
      });
    } else {
      PricingAPI.updGroup(this.state.id, payload, (data) => {
        console.log("updGroup()", data);
        this.props.history.push('/pricing');
      });
    }
  }

  render() {

    return (
      <div className="h-screen flex overflow-hidden bg-cool-gray-100">

        <Menu {...this.props} />
        <MobileMenu {...this.props} />

        <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">

          <Header {...this.props} />

          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

            {/* begin page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                            Pricing Group
                              </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    &nbsp;
                      </div>
                </div>
              </div>
            </div>
            {/* end page header */}

            <div className="mt-8">
              {/* begin content block */}
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="bg-white rounded-md shadow px-6 flex space-x-4 mb-8">
                    <li className="flex">
                      <div className="flex items-center">
                        <a href="/" className="text-gray-400 hover:text-gray-500">
                          <svg className="flex-shrink-0 h-5 w-5 transition duration-150 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                          </svg>
                        </a>
                        <span className="sr-only">Home</span>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center space-x-4">
                        <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <a href="/pricing" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Pricing</a>
                      </div>
                    </li>
                  </ol>
                </nav>

                <div className="mt-10 sm:mt-0">
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Group Information</h3>
                        <p className="mt-1 text-sm leading-5 text-gray-600">
                          The group details.
                            </p>
                      </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <TextInput
                                name="name"
                                type="text"
                                label="Group name"
                                value={this.state.name}
                                maxLength={85}
                                asterix={true}
                                onChange={this.onChange.bind(this)}
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <TextInput
                                name="order"
                                type="number"
                                label="Sort Order"
                                value={this.state.order}
                                maxLength={85}
                                asterix={true}
                                onChange={this.onChange.bind(this)}
                              />
                            </div>

                          </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 sm:px-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              {this.state.id !== 'new' &&
                                <svg className="delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this group?')) this.deleteGroup() }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                              }
                            </div>
                            <div className="col-span-6 sm:col-span-3 text-right">
                              <SubmitButton
                                text="Save"
                                onClick={this.save}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              {/* end content block */}
            </div>

          </main>
        </div>
      </div>

    )

  }

}

PricingGroupPage.propTypes = {
  firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    firstName: state.account.firstName,
  }
};

export default connect(mapStateToProps)(PricingGroupPage);