import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Title } from '../../components/Title';
import { TodaysMetrics } from '../../components/MetricSidebar';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import QuoteAPI from '../../api/QuoteAPI';
import { SelectBox } from '../../components/SelectBox';

class QuotesPage extends Component {

  constructor() {
    super();
    this.state = {
      keyword: '',
      quotes: [],
      reason: '',
      statuses: [{
        value: 'enquiry',
        label: 'Enquiry'
      }, {
        value: 'rfq_sent',
        label: 'RFQ Sent to Manufacturer'
      }, {
        value: 'quote_sent',
        label: 'Quote Sent'
      }, {
        value: 'quote_revised',
        label: 'Quote Revised'
      }, {
        value: 'invoice_raised',
        label: 'Invoice Raised'
      }, {
        value: 'payment_received',
        label: 'Deposit / Full Payment Received'
      }, {
        value: 'visit_arranged',
        label: 'Site Visit Arranged'
      }, {
        value: 'visit_revision',
        label: 'Site Visit Revision'
      }, {
        value: 'delivery_date',
        label: 'Delivery Date Received'
      }, {
        value: 'install_arranged',
        label: 'Installation Date Arranged'
      }, {
        value: 'install_complete',
        label: 'Installation Complete'
      }, {
        value: 'install_payment',
        label: 'Installation Payment Received'
      }, {
        value: 'follow_up',
        label: 'Courtesy Follow Up'
      }, {
        value: 'rejected',
        label: 'Rejected'
      }],
    }

  }

  componentDidMount() {
    this.loadQuotes();
  }

  loadQuotes() {
    let params = {};
    if (this.state.status !== '') {
      params = {
        status: this.state.status
      }
    }
    QuoteAPI.getQuotes(params, (data) => {
      console.log("quotes", data);
      this.setState({
        quotes: data,
      });
    })
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
    }, () => {
      if (name === 'status') {
        this.loadQuotes();
      }
    });
  }

  render() {

    return (
      <div className="h-screen flex overflow-hidden bg-cool-gray-100">

        <Menu {...this.props} />
        <MobileMenu {...this.props} />

        <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">

          <Header {...this.props} />

          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

            {/* begin page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                            Quotes
                              </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <span className="shadow-sm rounded-md">
                      <a href="/quotes/edit/new" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline-teal focus:border-teal-700 active:bg-teal-700 transition duration-150 ease-in-out">
                        Create new quote
                          </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* end page header */}

            <div className="mt-8">
              {/* begin content block */}

              <div className="hidden sm:block">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-end-7 col-span-2">
                      <SelectBox
                        name="status"
                        label=""
                        placeholder="Show All"
                        value={this.state.status}
                        maxLength={45}
                        asterix={true}
                        options={this.state.statuses}
                        onChange={this.onChange.bind(this)}
                        error={this.state.errStatus}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col mt-2">
                    <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                      <table className="min-w-full divide-y divide-cool-gray-200">
                        <thead>
                          <tr>
                            <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="10%">
                              Reference
                                </th>
                            <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="55%">
                              Project / Client
                                </th>
                            <th className="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="10%">
                              Amount
                                </th>
                            <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="20%">
                              Status
                                </th>
                            <th className="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="10%">
                              Date
                                </th>
                            <th className="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="5%">
                              &nbsp;
                                </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-cool-gray-200">
                          {this.state.quotes.length === 0 &&
                            <tr>
                              <td colSpan="6" className="px-6 py-4 whitespace-no-wrap">
                                <div className="text-sm leading-5 text-cool-gray-500 text-center">
                                  No quotes found.
                                            </div>
                              </td>
                            </tr>
                          }
                          {this.state.quotes.map((item, index) =>
                            <tr key={index} className="bg-white">
                              <td className="x-6 px-6 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                <a href={`/quotes/${item._id}`} className="group inline-flex space-x-2 truncate text-sm leading-5">
                                  <p className="text-cool-gray-500 truncate group-hover:text-cool-gray-900 transition ease-in-out duration-150">
                                    #HMG{item.quoteNumber}
                                  </p>
                                </a>
                              </td>
                              <td className="max-w-0 px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-900">
                                <div className="text-sm font-medium text-gray-900">
                                  {item.name}
                                </div>
                                <div className="text-sm text-gray-500">
                                  {item.company_id.name}
                                </div>
                              </td>
                              <td className="px-6 py-4 text-right whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                <span className="text-cool-gray-900 font-medium">&pound;{(parseFloat(item.price) + (((parseFloat(item.price) / 100) * parseFloat(item.discount) * -1)) + ((parseFloat(item.price) - ((parseFloat(item.price) / 100) * parseFloat(item.discount))) * 0.2)).toFixed(2)} </span>
                                    GBP
                                </td>
                              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800 capitalize">
                                  {item.status === 'enquiry' ? 'Enquiry' :
                                    item.status === 'rfq_sent' ? 'RFQ Sent to Manufacturer' :
                                      item.status === 'quote_sent' ? 'Quote Sent' :
                                        item.status === 'quote_revised' ? 'Quote Revised' :
                                          item.status === 'invoice_raised' ? 'Invoice Raised' :
                                            item.status === 'payment_received' ? 'Deposit / Full Payment Received' :
                                              item.status === 'visit_arranged' ? 'Site Visit Arranged' :
                                                item.status === 'visit_revision' ? 'Site Visit Revision' :
                                                  item.status === 'delivery_date' ? 'Delivery Date Received' :
                                                    item.status === 'install_arranged' ? 'Installation Date Arranged' :
                                                      item.status === 'install_complete' ? 'Installation Complete' :
                                                        item.status === 'install_payment' ? 'Installation Payment Received' :
                                                          item.status === 'follow_up' ? 'Courtesy Follow Up' :
                                                            item.status === 'rejected' ? 'Rejected' :
                                                              'Unknown'}
                                </span>
                              </td>
                              <td className="px-6 py-4 text-right whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                {moment(item.created).format("MMM DD, YYYY")}
                              </td>
                              <td className="px-6 py-4">
                                <div className="text-sm leading-5 text-gray-900 text-right">
                                  <a href={`/quotes/${item._id}`}>
                                    <svg alt="Edit" style={{ width: '16px' }} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                    </svg>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-cool-gray-200 sm:px-6">
                        <div className="hidden sm:block">
                          <p className="text-sm leading-5 text-cool-gray-700 span-line">
                            Showing
                                <span className="font-medium">1</span>
                                to
                                <span className="font-medium">10</span>
                                of
                                <span className="font-medium">20</span>
                                results
                                </p>
                        </div>
                        <div className="flex-1 flex justify-between sm:justify-end">
                          <a href="#" className="relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition ease-in-out duration-150">
                            Previous
                                </a>
                          <a href="#" className="ml-3 relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition ease-in-out duration-150">
                            Next
                                </a>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>

              {/* end content block */}
            </div>
          </main>
        </div>
      </div>
    )

  }

}

QuotesPage.propTypes = {
  firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    firstName: state.account.firstName,
  }
};

export default connect(mapStateToProps)(QuotesPage);