import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Title } from '../../components/Title';
import { TodaysMetrics } from '../../components/MetricSidebar';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import CompanyAPI from '../../api/CompanyAPI';
import ContactAPI from '../../api/ContactAPI';
import QuoteAPI from '../../api/QuoteAPI';
import UserAPI from '../../api/UserAPI';
import { Quotes } from '../../components/Quotes';
import { SelectBox } from '../../components/SelectBox';
import * as QueryString from "query-string";

class QuoteEditPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            company_id: '',
            contact_id: '',
            author_id: '',
            reference: '',
            manufacturer_ref: '',
            status: '',
            quoteNumber: '',
            name: '',
            email: '',
            line1: '',
            contractor_id: '',
            line2: '',
            town: '',
            county: '',
            postcode: '',
            discount: 0,
            expected_date: '',
            reason: '',
            clients: [],
            users: [],
            contacts: [],
            statuses: [{
                value: 'enquiry',
                label: 'Enquiry'
            }, {
                value: 'rfq_sent',
                label: 'RFQ Sent to Manufacturer'
            }, {
                value: 'quote_sent',
                label: 'Quote Sent'
            }, {
                value: 'quote_revised',
                label: 'Quote Revised'
            }, {
                value: 'invoice_raised',
                label: 'Invoice Raised'
            }, {
                value: 'payment_received',
                label: 'Deposit / Full Payment Received'
            }, {
                value: 'visit_arranged',
                label: 'Site Visit Arranged'
            }, {
                value: 'visit_revision',
                label: 'Site Visit Revision'
            }, {
                value: 'delivery_date',
                label: 'Delivery Date Received'
            }, {
                value: 'install_arranged',
                label: 'Installation Date Arranged'
            }, {
                value: 'install_complete',
                label: 'Installation Complete'
            }, {
                value: 'install_payment',
                label: 'Installation Payment Received'
            }, {
                value: 'follow_up',
                label: 'Courtesy Follow Up'
            }, {
                value: 'rejected',
                label: 'Rejected'
            }],
            errClient: '',
            errContact: '',
            errAuthor: '',
            errStatus: '',
            errName: '',
            reason: '',
            companyName: '',
            firstName: '',
            lastName: '',
            contractors: [],
            errContractor: '',
            contractorName: '',
            sendEmail: true,
            modal: true,
        }

        /*
        Enquiry
        RFQ Sent
        Quote Sent
        Quote Revised
        Invoice Raised
        Deposit / Full Payment Received
        Site Visit Arranged (Date + Attendee)
        Site Visit Revision
        Delivery Date Received (Date)
        Installation Date Arranged (Date + Attendee)
        Installation Complete
        Installation Payment Received
        Courtesy Follow Up
        */

        this.save = this.save.bind(this);

    }

    componentDidMount() {

        UserAPI.getUserData((data) => {
            this.setState({
                author_id: data._id,
            });
        })

        const params = QueryString.parse(this.props.location.search);

        if (params.client) {
            this.setState({
                company_id: params.client
            }, () => {
                this.loadContacts();
                this.loadCompany();
            });
        }

        this.loadUsers();
        this.loadClients();
        this.loadContractors();
        if (this.state.id !== 'new') {
            this.loadQuote();
        }
    }

    loadUsers() {
        UserAPI.getUsers((data) => {
            var _arr = [];
            for (var i = 0; i < data.length; i++) {
                _arr.push({
                    value: data[i].id,
                    label: data[i].firstname + ' ' + data[i].lastname,
                });
            }
            this.setState({
                users: _arr
            });
        });
    }

    loadContacts() {
        ContactAPI.getContacts({ company: this.state.company_id }, (data) => {
            var _arr = [];
            _arr.push({
                value: 'new',
                label: 'New Contact',
            });
            for (var i = 0; i < data.length; i++) {
                _arr.push({
                    value: data[i]._id,
                    label: data[i].firstname + ' ' + data[i].lastname,
                });
            }
            this.setState({
                contacts: _arr
            });
        });
    }

    deleteQuote() {
        QuoteAPI.delQuote(this.state.id, (data) => {
            this.props.history.push(`/quotes`);
        });
    }

    loadClients() {
        CompanyAPI.getCompanies("CLIENT", (data) => {
            var _arr = [];
            _arr.push({
                value: 'new',
                label: 'New Client',
            });
            for (var i = 0; i < data.length; i++) {
                _arr.push({
                    value: data[i]._id,
                    label: data[i].name,
                });
            }
            this.setState({
                clients: _arr
            });
        });
    }

    loadContractors() {
        CompanyAPI.getCompanies("CONTRACTOR", (data) => {
            var _arr = [];
            _arr.push({
                value: 'new',
                label: 'New Contractor',
            });
            for (var i = 0; i < data.length; i++) {
                _arr.push({
                    value: data[i]._id,
                    label: data[i].name,
                });
            }
            this.setState({
                contractors: _arr
            });
        });
    }


    loadQuote() {
        QuoteAPI.getQuote(this.state.id, (data) => {
            console.log("Quote()", data);
            this.setState({
                company_id: data.company_id._id,
                contact_id: data.contact_id._id,
                author_id: data.author_id._id,
                reference: data.reference,
                manufacturer_ref: data.manufacturer_ref,
                status: data.status,
                quoteNumber: data.quoteNumber,
                name: data.name,
                email: data.email,
                line1: data.line1,
                line2: data.line2,
                town: data.town,
                county: data.county,
                postcode: data.postcode,
                expected_date: data.expected_date,
                reason: data.reason,
                contractor_id: data.contractor_id,
            }, () => {
                this.loadContacts();
            });
        })
    }

    loadCompany() {
        CompanyAPI.getCompany(this.state.company_id, (data) => {
            this.setState({
                discount: data.discount,
            });
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
            errClient: '',
            errContact: '',
            errAuthor: '',
            errStatus: '',
            errName: '',
        }, () => {
            if (name === 'company_id') {
                if (value === 'new') {
                    this.setState({
                        contact_id: 'new'
                    });
                } else {
                    this.loadContacts();
                    this.loadCompany();
                }
            }
        });
    }

    save() {

        var _ok = true;

        if (this.state.company_id === '') {
            _ok = false;
            this.setState({
                errClient: 'Please select a client'
            });
        }
        if (this.state.contact_id === '') {
            _ok = false;
            this.setState({
                errContact: 'Please select a contact'
            });
        }
        if (this.state.author_id === '') {
            _ok = false;
            this.setState({
                errAuthor: 'Please select an account manager'
            });
        }
        if (this.state.status === '') {
            _ok = false;
            this.setState({
                errStatus: 'Please select a status'
            });
        }
        if (this.state.name === '') {
            _ok = false;
            this.setState({
                errName: 'Please enter a name'
            });
        }

        if (_ok) {

            let payload = {
                company_id: this.state.company_id,
                contact_id: this.state.contact_id,
                author_id: this.state.author_id,
                reference: this.state.reference,
                manufacturer_ref: this.state.manufacturer_ref,
                status: this.state.status,
                name: this.state.name,
                email: this.state.email,
                line1: this.state.line1,
                line2: this.state.line2,
                town: this.state.town,
                county: this.state.county,
                postcode: this.state.postcode,
                discount: this.state.discount,
                expected_date: this.state.expected_date,
                reason: this.state.reason,
                companyName: this.state.companyName,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                contractorName: this.state.contractorName,
                sendEmail: this.state.sendEmail,
            }

            if (this.state.contractor_id) {
                payload.contractor_id = this.state.contractor_id;
            }

            if (this.state.id === 'new') {
                QuoteAPI.addQuote(payload, (data) => {
                    console.log("addQuote()", data);
                    this.props.history.push(`/quotes/${data._id}`);
                });
            } else {
                QuoteAPI.updQuote(this.state.id, payload, (data) => {
                    console.log("updQuote()", data);
                    this.props.history.push(`/quotes/${this.state.id}`);
                });
            }
        }

    }

    render() {

        return (
            <div className="h-screen flex overflow-hidden bg-cool-gray-100">

                <Menu {...this.props} />
                <MobileMenu {...this.props} />

                <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">

                    <Header {...this.props} />

                    <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

                        {/* begin page header */}
                        <div className="bg-white shadow">
                            <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                                    <div className="flex-1 min-w-0">
                                        <div className="flex items-center">
                                            <div>
                                                <div className="flex items-center">
                                                    <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                                                        Quote
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end page header */}

                        <div className="mt-8">
                            {/* begin content block */}
                            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

                                <nav className="flex" aria-label="Breadcrumb">
                                    <ol className="bg-white rounded-md shadow px-6 flex space-x-4 mb-8">
                                        <li className="flex">
                                            <div className="flex items-center">
                                                <a href="/" className="text-gray-400 hover:text-gray-500">
                                                    <svg className="flex-shrink-0 h-5 w-5 transition duration-150 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                                    </svg>
                                                </a>
                                                <span className="sr-only">Home</span>
                                            </div>
                                        </li>
                                        <li className="flex">
                                            <div className="flex items-center space-x-4">
                                                <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                                </svg>
                                                <a href="/quotes" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Quotes</a>
                                            </div>
                                        </li>
                                        {this.state.id !== 'new' &&
                                            <li className="flex">
                                                <div className="flex items-center space-x-4">
                                                    <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                                    </svg>
                                                    <a href={`/quotes/${this.state.id}`} aria-current="page" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">#HMG{this.state.quoteNumber}</a>
                                                </div>
                                            </li>
                                        }
                                        <li className="flex">
                                            <div className="flex items-center space-x-4">
                                                <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                                </svg>
                                                {this.state.id === 'new' ?
                                                    <a href={`/quotes`} aria-current="page" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Create Quote</a>
                                                    :
                                                    <a href={`/quotes/edit/${this.state.id}`} aria-current="page" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Edit Quote</a>
                                                }
                                            </div>
                                        </li>
                                    </ol>
                                </nav>


                                <div className="mt-10 sm:mt-0">
                                    <div className="md:grid md:grid-cols-3 md:gap-6">
                                        <div className="md:col-span-1">
                                            <div className="px-4 sm:px-0">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Quote Information</h3>
                                                <p className="mt-1 text-sm leading-5 text-gray-600">
                                                    The customers personal details.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-5 md:mt-0 md:col-span-2">
                                            <div className="shadow overflow-hidden sm:rounded-md">
                                                <div className="px-4 py-5 bg-white sm:p-6">
                                                    <div className="grid grid-cols-6 gap-6">
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <SelectBox
                                                                name="company_id"
                                                                label="Client"
                                                                placeholder="Select..."
                                                                value={this.state.company_id}
                                                                maxLength={45}
                                                                asterix={true}
                                                                options={this.state.clients}
                                                                onChange={this.onChange.bind(this)}
                                                                error={this.state.errClient}
                                                            />
                                                            {this.state.company_id === 'new' &&
                                                                <div className="grid gap-6 mt-6">
                                                                    <div>
                                                                        <TextInput
                                                                            name="companyName"
                                                                            type="text"
                                                                            label="Client Name"
                                                                            value={this.state.companyName}
                                                                            maxLength={45}
                                                                            onChange={this.onChange.bind(this)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <SelectBox
                                                                name="contact_id"
                                                                label="Contact"
                                                                placeholder="Select..."
                                                                value={this.state.contact_id}
                                                                maxLength={45}
                                                                asterix={true}
                                                                options={this.state.contacts}
                                                                onChange={this.onChange.bind(this)}
                                                                error={this.state.errContact}
                                                                disabled={this.state.company_id === 'new'}
                                                            />
                                                            {(this.state.contact_id === 'new' || this.state.company_id === 'new') &&
                                                                <div className="grid grid-cols-2 gap-6 mt-6">
                                                                    <div>
                                                                        <TextInput
                                                                            name="firstName"
                                                                            type="text"
                                                                            label="First Name"
                                                                            value={this.state.firstName}
                                                                            maxLength={45}
                                                                            onChange={this.onChange.bind(this)}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <TextInput
                                                                            name="lastName"
                                                                            type="text"
                                                                            label="Last Name"
                                                                            value={this.state.lastName}
                                                                            maxLength={45}
                                                                            onChange={this.onChange.bind(this)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <SelectBox
                                                                name="author_id"
                                                                label="Account Manager"
                                                                placeholder="Select..."
                                                                value={this.state.author_id}
                                                                maxLength={45}
                                                                asterix={true}
                                                                options={this.state.users}
                                                                onChange={this.onChange.bind(this)}
                                                                error={this.state.errAuthor}
                                                            />
                                                        </div>
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <SelectBox
                                                                name="status"
                                                                label="Quote Status"
                                                                placeholder="Select..."
                                                                value={this.state.status}
                                                                maxLength={45}
                                                                asterix={true}
                                                                options={this.state.statuses}
                                                                onChange={this.onChange.bind(this)}
                                                                error={this.state.errStatus}
                                                            />
                                                        </div>
                                                        {this.state.status === 'rejected' &&
                                                            <div className="col-span-6 sm:col-span-3">
                                                                <TextInput
                                                                    name="reason"
                                                                    type="text"
                                                                    label="Rejection Reason"
                                                                    value={this.state.reason}
                                                                    maxLength={1000}
                                                                    onChange={this.onChange.bind(this)}
                                                                />
                                                            </div>
                                                        }
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <TextInput
                                                                name="reference"
                                                                type="text"
                                                                label="Customer Reference"
                                                                value={this.state.reference}
                                                                maxLength={45}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <TextInput
                                                                name="manufacturer_ref"
                                                                type="text"
                                                                label="Manufacturer Reference"
                                                                value={this.state.manufacturer_ref}
                                                                maxLength={45}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <TextInput
                                                                name="expected_date"
                                                                type="date"
                                                                label="Expected Order Date"
                                                                value={this.state.expected_date}
                                                                maxLength={45}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="hidden sm:block">
                                    <div className="py-5">
                                        <div className="border-t border-gray-200"></div>
                                    </div>
                                </div>

                                <div className="mt-10 sm:mt-0">
                                    <div className="md:grid md:grid-cols-3 md:gap-6">
                                        <div className="md:col-span-1">
                                            <div className="px-4 sm:px-0">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Project Information</h3>
                                                <p className="mt-1 text-sm leading-5 text-gray-600">
                                                    The project name and address.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-5 md:mt-0 md:col-span-2">
                                            <div className="shadow overflow-hidden sm:rounded-md">
                                                <div className="px-4 py-5 bg-white sm:p-6" style={{ paddingBottom: 0 }}>
                                                    <div className="grid grid-cols-6 gap-6">

                                                        <div className="col-span-6 sm:col-span-3">
                                                            <TextInput
                                                                name="name"
                                                                type="text"
                                                                label="Project name"
                                                                value={this.state.name}
                                                                maxLength={45}
                                                                asterix={true}
                                                                onChange={this.onChange.bind(this)}
                                                                error={this.state.errName}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="px-4 py-5 bg-white sm:p-6">
                                                    <div className="grid grid-cols-6 gap-6">

                                                        <div className="col-span-6 sm:col-span-3">
                                                            <TextInput
                                                                name="line1"
                                                                type="text"
                                                                label="Address (line 1)"
                                                                value={this.state.line1}
                                                                maxLength={45}
                                                                asterix={true}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <TextInput
                                                                name="line2"
                                                                type="text"
                                                                label="Address (line 2)"
                                                                value={this.state.line2}
                                                                maxLength={45}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>

                                                        <div className="col-span-6 sm:col-span-3">
                                                            <TextInput
                                                                name="town"
                                                                type="text"
                                                                label="Town"
                                                                value={this.state.town}
                                                                maxLength={45}
                                                                asterix={true}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <TextInput
                                                                name="county"
                                                                type="text"
                                                                label="County"
                                                                value={this.state.county}
                                                                maxLength={45}
                                                                asterix={true}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>

                                                        <div className="col-span-6 sm:col-span-3">
                                                            <TextInput
                                                                name="postcode"
                                                                type="text"
                                                                label="Post code"
                                                                value={this.state.postcode}
                                                                maxLength={45}
                                                                asterix={true}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="hidden sm:block">
                                    <div className="py-5">
                                        <div className="border-t border-gray-200"></div>
                                    </div>
                                </div>

                                <div className="mt-10 sm:mt-0">
                                    <div className="md:grid md:grid-cols-3 md:gap-6">
                                        <div className="md:col-span-1">
                                            <div className="px-4 sm:px-0">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Payment Settings</h3>
                                                <p className="mt-1 text-sm leading-5 text-gray-600">
                                                    The specific payment settings for this quote.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-5 md:mt-0 md:col-span-2">
                                            <div className="shadow overflow-hidden sm:rounded-md">
                                                <div className="px-4 py-5 bg-white sm:p-6">
                                                    <div className="grid grid-cols-6 gap-6">

                                                        <div className="col-span-6 sm:col-span-3">
                                                            <TextInput
                                                                name="discount"
                                                                type="number"
                                                                label="Discount rate (%)"
                                                                value={this.state.discount}
                                                                maxLength={45}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="hidden sm:block">
                                    <div className="py-5">
                                        <div className="border-t border-gray-200"></div>
                                    </div>
                                </div>

                                <div className="mt-10 sm:mt-0">
                                    <div className="md:grid md:grid-cols-3 md:gap-6">
                                        <div className="md:col-span-1">
                                            <div className="px-4 sm:px-0">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Contractor</h3>
                                                <p className="mt-1 text-sm leading-5 text-gray-600">
                                                    The contractor assigned to this job.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-5 md:mt-0 md:col-span-2">
                                            <div className="shadow overflow-hidden sm:rounded-md">
                                                <div className="px-4 py-5 bg-white sm:p-6">
                                                    <div className="grid grid-cols-6 gap-6">

                                                        <div className="col-span-6 sm:col-span-3">
                                                            <SelectBox
                                                                name="contractor_id"
                                                                label="Contractor"
                                                                placeholder="Select..."
                                                                value={this.state.contractor_id}
                                                                maxLength={45}
                                                                asterix={true}
                                                                options={this.state.contractors}
                                                                onChange={this.onChange.bind(this)}
                                                                error={this.state.errContractor}
                                                            />
                                                            {this.state.contractor_id === 'new' &&
                                                                <div className="grid gap-6 mt-6">
                                                                    <div>
                                                                        <TextInput
                                                                            name="contractorName"
                                                                            type="text"
                                                                            label="Contractor Name"
                                                                            value={this.state.contractorName}
                                                                            maxLength={45}
                                                                            onChange={this.onChange.bind(this)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="hidden sm:block">
                                    <div className="py-5">
                                        <div className="border-t border-gray-200"></div>
                                    </div>
                                </div>

                                <div className="mt-10 sm:mt-0">
                                    <div className="md:grid md:grid-cols-3 md:gap-6">
                                        <div className="md:col-span-1">
                                            <div className="px-4 sm:px-0">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Communication</h3>
                                                <p className="mt-1 text-sm leading-5 text-gray-600">
                                                    Send this quote to the client.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-5 md:mt-0 md:col-span-2">
                                            <div className="shadow overflow-hidden sm:rounded-md">
                                                <div className="px-4 py-5 bg-white sm:p-6">
                                                    <div className="grid grid-cols-6 gap-6">

                                                        <div className="col-span-6 sm:col-span-3">
                                                            <SwitchButton
                                                                label="Communication"
                                                                name="sendEmail"
                                                                text="Email quote to client?"
                                                                value={this.state.sendEmail}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                                <div className="grid grid-cols-2">

                                                    <div className="text-left">
                                                        {this.state.id !== 'new' &&
                                                            <div className="cursor-pointer" onClick={() => { if (window.confirm('Are you sure you want to delete this quote?')) this.deleteQuote() }}>
                                                                <svg alt="Delete" style={{ width: '16px' }} className="inline-block ml-4 w-6 h-6 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                </svg>
                                                                <span style={{ fontSize: 11, marginLeft: 5 }}>Delete Quote</span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <SubmitButton
                                                            text="Save"
                                                            onClick={this.save}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* end content block */}
                        </div>

                    </main>
                </div >
            </div >

        )

    }

}

QuoteEditPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(QuoteEditPage);