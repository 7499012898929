import React from 'react';

class MetricTitle extends React.Component {

    render() {
        return (
            <div className="metric-title">
                <div className="label">{this.props.title}</div>
                <div className="compare">{this.props.compare ? this.props.compare : ''}</div>
                <div className="value">{this.props.label}</div>
            </div>
        )
    }

};

export default MetricTitle;