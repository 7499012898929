import React from 'react';

const NarrowContainer = ({ props, children }) => (
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-sm-10">
                {children}
            </div>
        </div>
    </div>
);

export default NarrowContainer;