import React from 'react';
import { withRouter, Link } from 'react-router-dom';

class BlockLink extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="block-link">
                <div className="icon"><i className={`fas ${this.props.icon}`}></i></div>
                <p><Link to={this.props.url}>{this.props.title}</Link><br />{this.props.summary}</p>
            </div>
        )
    }
};

export default withRouter(BlockLink);