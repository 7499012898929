import React from 'react';
import {ResponsiveContainer, BarChart, ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

class ColumnLineChart extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
    
        return (
            <div className="chart">
                <ResponsiveContainer width="100%" height={200}>
                    <ComposedChart data={this.props.data}
                        margin={{top: 5, right: 0, left: 0, bottom: 5}}>
                        <CartesianGrid strokeDasharray="1 1" vertical={false} />
                        <XAxis dataKey="name" interval={5} />
                        <YAxis width={30} />
                        <Tooltip/>
                        <Bar dataKey="today" name="Today" fill="#40d4d1" />
                        <Line type='monotone' dataKey='compare' name="Last Week" stroke='#ff7300' />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
    }
};

export default ColumnLineChart;