import apiRequest from '.';
import APIUtils from './APIUtils';

class ProductAPI {

    getProducts(line, group, cb) {
        apiRequest({ endpoint: `products?lineId=${line}&group=${group}` }, cb);
    }

    getProduct(id, cb) {
        apiRequest({ endpoint: `products/${id}` }, cb);
    }

    getAllProducts(cb) {
        apiRequest({ endpoint: `all-products` }, cb);
    }

    addProduct(payload, cb) {
        apiRequest({ endpoint: `products`, method: 'POST', payload }, cb);
    }

    updProduct(id, payload, cb) {
        apiRequest({ endpoint: `products/${id}`, method: 'PUT', payload }, cb);
    }

    deleteProduct(id, cb) {
        apiRequest({ endpoint: `products/${id}`, method: 'DELETE' }, cb);
    }

    getColors(cb) {
        apiRequest({ endpoint: `colors` }, cb);
    }

}

export default new ProductAPI();