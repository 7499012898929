import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import QuoteAPI from '../../api/QuoteAPI';
import ProductAPI from '../../api/ProductAPI';
import PricingAPI from '../../api/PricingAPI';
import { SelectBox } from '../../components/SelectBox';
import * as QueryString from "query-string";
import { TextInput } from '../../components/TextInput';

class QuoteItemPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      quote_id: props.match.params.quote,
      quoteNumber: '',
      reference: '',
      quantity: 1,
      width: 0,
      height: 0,
      frame_width: 0,
      frame_height: 0,
      base: '',
      finish: '',
      direction: '',
      handing: '',
      presets: [],
      base_products: [],
      finish_products: [],
      direction_products: [],
      handing_products: [],
      changes: false,
      price: 0,
      item: {},
      pricing: [],
      item_options: [],
      manual: false,
      item_description: '',
      vat: [],
      vat_id: '',
    }

    this.save = this.save.bind(this);

  }

  componentDidMount() {
    this.loadPricing();
    this.loadVatRates();
    //this.loadProducts('base', 'base_products');
    //this.loadProducts('finish', 'finish_products');
    //this.loadProducts('direction', 'direction_products');
    //this.loadProducts('handing', 'handing_products');
    this.loadQuote();
    if (this.state.id !== 'new') {
      this.loadItem();
    }
  }


  loadPricing() {
    PricingAPI.getProducts(this.state.id, (data) => {
      var _arr = [];
      for (var i = 0; i < data.length; i++) {
        _arr.push({
          id: data[i].id,
          name: data[i].name,
          value: data[i].value,
          options: data[i].options,
          order: data[i].order,
        });
      }

      this.setState({
        pricing: _arr
      });
    })
  }

  loadProducts(group, obj) {
    ProductAPI.getProducts(group, (data) => {
      var _arr = [];
      for (var i = 0; i < data.length; i++) {
        _arr.push({
          value: data[i].id,
          label: data[i].name,
        });
      }
      this.setState({
        [obj]: _arr
      });
    })
  }

  loadVatRates(obj) {
    PricingAPI.getVatRates((data) => {
      var _arr = [];
      for (var i = 0; i < data.length; i++) {
        _arr.push({
          value: data[i]._id,
          label: data[i].name,
        });
      }
      this.setState({
        vat: _arr
      });
    })
  }

  loadItem() {
    QuoteAPI.getQuoteItem(this.state.id, (data) => {
      console.log("getQuoteItem()", data);
      this.setState({
        reference: data.reference,
        quantity: data.quantity,
        price: data.price,
        item: data,
        width: data.width,
        height: data.height,
        frame_width: data.frame_width,
        frame_height: data.frame_height,
        item_options: data.item_options,
        manual: data.manual,
        item_description: data.item_description,
        vat_id: data.vat_id,
      });
    });
  }

  loadQuote() {
    QuoteAPI.getQuote(this.state.quote_id, (data) => {
      console.log("Quote()", data);
      this.setState({
        quoteNumber: data.quoteNumber,
      });
    })
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
      changes: true,
    });
  }

  handleOptionChange = (idx) => (evt) => {
    console.log("handleOptionChange");
    console.log("index: ", idx);
    evt.preventDefault();
    const _target = evt.target;
    const _value = _target.type === 'checkbox' ? _target.checked : _target.value;
    const _name = _target.name;
    console.log("value: ", _value);
    const newPricing = this.state.pricing.map((option, sidx) => {
      if (idx !== sidx) return option;

      return { ...option, value: _value };
    });
    this.setState({ pricing: newPricing }, () => {
      this.saveAndRefresh();
    });
  }

  saveAndRefresh() {

    var _options = [];
    for (var i = 0; i < this.state.pricing.length; i++) {
      if (this.state.pricing[i].value) {
        _options.push({
          group_id: this.state.pricing[i].id,
          product_id: this.state.pricing[i].value,
        });
      }
    }

    let payload = {
      quote_id: this.state.quote_id,
      reference: this.state.reference,
      quantity: this.state.quantity,
      width: this.state.width,
      height: this.state.height,
      frame_width: this.state.frame_width,
      frame_height: this.state.frame_height,
      item_options: _options,
      manual: this.state.manual,
      item_description: this.state.item_description,
      price: this.state.price,
      vat_id: this.state.vat_id,
    }

    console.log(payload);

    QuoteAPI.updQuoteItem(this.state.id, payload, (data) => {
      console.log("updQuoteItem()", data);
      this.loadPricing();
    });

  }

  deleteItem() {
    QuoteAPI.delQuoteItem(this.state.id, (data) => {
      this.props.history.push(`/quotes/${this.state.quote_id}`);
    });
  }

  save() {

    var _options = [];
    for (var i = 0; i < this.state.pricing.length; i++) {
      if (this.state.pricing[i].value) {
        _options.push({
          group_id: this.state.pricing[i].id,
          product_id: this.state.pricing[i].value,
        });
      }
    }

    let payload = {
      quote_id: this.state.quote_id,
      reference: this.state.reference,
      quantity: this.state.quantity,
      width: this.state.width,
      height: this.state.height,
      frame_width: this.state.frame_width,
      frame_height: this.state.frame_height,
      item_options: _options,
      manual: this.state.manual,
      item_description: this.state.item_description,
      price: this.state.price,
      vat_id: this.state.vat_id,
    }

    console.log(payload);

    if (this.state.id === 'new') {
      QuoteAPI.addQuoteItem(payload, (data) => {
        console.log("addQuoteItem()", data);
        if (this.state.manual) {
          this.props.history.push(`/quotes/${this.state.quote_id}`);
        } else {
          this.props.history.push(`/quotes/${this.state.quote_id}/${data._id}`);
        }
      });
    } else {
      QuoteAPI.updQuoteItem(this.state.id, payload, (data) => {
        console.log("updQuoteItem()", data);
        if (this.state.manual) {
          this.props.history.push(`/quotes/${this.state.quote_id}`);
        } else {
          this.props.history.push(`/quotes/${this.state.quote_id}/${this.state.id}`);
        }
      });
    }

  }

  render() {

    return (
      <div className="h-screen flex overflow-hidden bg-cool-gray-100">

        <Menu {...this.props} />
        <MobileMenu {...this.props} />

        <div className="flex-1 overflow-auto focus:outline-none" tabIndex="0">

          <Header {...this.props} />

          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

            {/* begin page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                            Quote
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
            {/* end page header */}

            <div className="mt-8">
              {/* begin content block */}
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="bg-white rounded-md shadow px-6 flex space-x-4 mb-8">
                    <li className="flex">
                      <div className="flex items-center">
                        <a href="/" className="text-gray-400 hover:text-gray-500">
                          <svg className="flex-shrink-0 h-5 w-5 transition duration-150 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                          </svg>
                        </a>
                        <span className="sr-only">Home</span>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center space-x-4">
                        <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <a href="/quotes" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Quotes</a>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center space-x-4">
                        <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <a href={`/quotes/${this.state.quote_id}`} aria-current="page" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">#HMG{this.state.quoteNumber}</a>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center space-x-4">
                        <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        {this.state.id === 'new' ?
                          <a href={`/quotes/${this.state.quote_id}/new`} aria-current="page" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">New Item</a>
                          :
                          <a href={`/quotes/${this.state.quote_id}/${this.state.id}`} aria-current="page" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Edit Item</a>
                        }
                      </div>
                    </li>
                  </ol>
                </nav>





                {(this.state.changes === false && this.state.id !== 'new' && !this.state.manual && this.state.item_options.length > 0) &&
                  <div>
                    <div className="bg-white shadow sm:rounded-lg">
                      <div className="md:grid md:grid-cols-1 md:gap-6">

                        {/* to be added
                        <div className="md:col-span-1 px-4 py-5">
                          <p className="mt-1 text-sm text-gray-500 text-center">
                            [preview image]
                          </p>
                        </div>
                        */}

                        <div className="md:col-span-1 px-4 py-5">

                          <dl className="grid grid-cols-8 gap-x-4 gap-y-2 sm:grid-cols-8">

                            {this.state.item_options.sort((a, b) => a.group_id.order > b.group_id.order ? 1 : -1).map((item, index) =>
                              <div key={index} className="sm:col-span-2">
                                <dt className="text-xs font-medium text-gray-500">
                                  {item.group_id.name}
                                </dt>
                                <dd className="text-xs text-gray-900">
                                  {item.product_id.name}
                                </dd>
                              </div>
                            )}

                          </dl>

                        </div>

                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 mt-2 text-right">&pound;{this.state.price.toFixed(2)}</h3>
                      </div>

                    </div>

                    <div className="hidden sm:block">
                      <div className="py-5">
                        <div className="border-t border-gray-200"></div>
                      </div>
                    </div>
                  </div>
                }


                <div className="bg-white shadow sm:rounded-lg">
                  <div className="md:grid md:grid-cols-3 md:gap-6">

                    <div className="md:col-span-1 px-4 py-5">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">Item Details</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Details for this item
                      </p>
                    </div>

                    <div className="mt-5 md:mt-0 md:col-span-2 px-4 py-5">

                      <div className="grid grid-cols-2 gap-6">
                        <div className="col-span-2 sm:col-span-1">
                          <SwitchButton
                            label="Manual Item"
                            name="manual"
                            text="Add a manual item?"
                            value={!this.state.manual ? false : this.state.manual}
                            onChange={this.onChange.bind(this)}
                          />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <TextInput
                            name="reference"
                            label="Reference"
                            value={this.state.reference}
                            maxLength={45}
                            onChange={this.onChange.bind(this)}
                            error={this.state.errReference}
                          />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <TextInput
                            name="quantity"
                            label="Quantity"
                            type="number"
                            value={this.state.quantity}
                            maxLength={45}
                            asterix={true}
                            onChange={this.onChange.bind(this)}
                            error={this.state.errQuantity}
                          />
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                          <SelectBox
                            name="vat_id"
                            label="VAT Rate"
                            placeholder="Select..."
                            value={this.state.vat_id}
                            maxLength={45}
                            asterix={true}
                            options={this.state.vat}
                            onChange={this.onChange.bind(this)}
                            error={this.state.errVat}
                          />
                        </div>

                        {this.state.manual &&
                          <div className="col-span-2 sm:col-span-1">
                            <TextInput
                              name="price"
                              label="Price"
                              type="number"
                              value={this.state.price}
                              maxLength={45}
                              asterix={true}
                              onChange={this.onChange.bind(this)}
                              error={this.state.errPrice}
                            />
                          </div>
                        }
                        {this.state.manual &&
                          <div className="col-span-2 sm:col-span-1">
                            <TextInput
                              name="item_description"
                              label="Description"
                              type="text"
                              value={this.state.item_description}
                              maxLength={45}
                              asterix={true}
                              onChange={this.onChange.bind(this)}
                              error={this.state.errDescription}
                            />
                          </div>
                        }

                        {!this.state.manual &&
                          <div className="col-span-2 sm:col-span-1">
                            <TextInput
                              name="width"
                              label="Structural Width (mm)"
                              type="number"
                              value={this.state.width}
                              maxLength={45}
                              asterix={true}
                              onChange={this.onChange.bind(this)}
                              error={this.state.errWidth}
                            />
                          </div>
                        }
                        {!this.state.manual &&
                          <div className="col-span-2 sm:col-span-1">
                            <TextInput
                              name="height"
                              type="number"
                              label="Structural Height (mm)"
                              value={this.state.height}
                              maxLength={45}
                              asterix={true}
                              onChange={this.onChange.bind(this)}
                              error={this.state.errHeight}
                            />
                          </div>
                        }

                        {!this.state.manual &&
                          <div className="col-span-2 sm:col-span-1">
                            <TextInput
                              name="frame_width"
                              label="Frame Width (mm)"
                              type="number"
                              value={parseFloat(this.state.width) > 10 ? parseFloat(this.state.width) - 10 : 0}
                              maxLength={45}
                              disabled={true}
                              onChange={this.onChange.bind(this)}
                              error={this.state.errFrameWidth}
                            />
                          </div>
                        }
                        {!this.state.manual &&
                          <div className="col-span-2 sm:col-span-1">
                            <TextInput
                              name="frame_height"
                              type="number"
                              label="Frame Height (mm)"
                              value={parseFloat(this.state.height) > 5 ? parseFloat(this.state.height) - 5 : 0}
                              maxLength={45}
                              disabled={true}
                              onChange={this.onChange.bind(this)}
                              error={this.state.errFrameHeight}
                            />
                          </div>
                        }

                      </div>



                    </div>

                  </div>

                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        {this.state.id !== 'new' &&
                          <svg className="delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this item?')) this.deleteItem() }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        }
                      </div>
                      <div className="col-span-6 sm:col-span-3 text-right">
                        <SubmitButton
                          text="Save"
                          onClick={this.save}
                        />
                      </div>
                    </div>

                  </div>

                </div>

                {!this.state.manual &&
                  <div className="hidden sm:block">
                    <div className="py-5">
                      <div className="border-t border-gray-200"></div>
                    </div>
                  </div>
                }

                {(!this.state.manual && this.state.id !== 'new') &&
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="md:grid md:grid-cols-3 md:gap-6">

                      <div className="md:col-span-1 px-4 py-5">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Configration</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Configuration settings
                        </p>
                      </div>

                      <div className="mt-5 md:mt-0 md:col-span-2 px-4 py-5">
                        <div className="grid grid-cols-2 gap-6">

                          {this.state.pricing.sort((a, b) => a.order > b.order ? 1 : -1).map((item, index) =>
                            item.options.length > 0 &&
                            <div key={index} className="col-span-2 sm:col-span-1">
                              <div>
                                <label className="block text-sm font-medium leading-5 text-gray-700">{item.name}</label>
                                <select
                                  className="mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  value={item.value}
                                  onChange={this.handleOptionChange(index)}
                                  autoComplete="off">
                                  <option value="">Select...</option>
                                  {item.options.map((option, index) =>
                                    <option key={index} value={option.value}>{option.label}</option>
                                  )}
                                </select>
                              </div>
                            </div>
                          )}

                        </div>
                      </div>


                      {/*

      <div className="mt-5 md:mt-0 md:col-span-2 px-4 py-5">
          
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 sm:col-span-1">
            <SelectBox 
                name="base"
                label="Door Quoted"
                placeholder="Select..."
                value={this.state.base}
                maxLength={45}
                asterix={true}
                options={this.state.base_products}
                onChange={this.onChange.bind(this)}
                error={this.state.errBase}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <SelectBox 
                name="handing"
                label="Handing"
                placeholder="Select..."
                value={this.state.handing}
                maxLength={45}
                asterix={true}
                options={this.state.handing_products}
                onChange={this.onChange.bind(this)}
                error={this.state.errHanding}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <SelectBox 
                name="direction"
                label="Opening Direction"
                placeholder="Select..."
                value={this.state.direction}
                maxLength={45}
                asterix={true}
                options={this.state.direction_products}
                onChange={this.onChange.bind(this)}
                error={this.state.errDirection}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <SelectBox 
                name="preset_id"
                label="Structural Width x Height"
                placeholder="Select..."
                value={this.state.company_id}
                maxLength={45}
                asterix={true}
                options={this.state.presets}
                onChange={this.onChange.bind(this)}
                error={this.state.errClient}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <SelectBox 
                name="preset_id"
                label="Frame Width x Height"
                placeholder="Select..."
                value={this.state.company_id}
                maxLength={45}
                asterix={true}
                options={this.state.presets}
                onChange={this.onChange.bind(this)}
                error={this.state.errClient}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <SelectBox 
                name="preset_id"
                label="Frame Material"
                placeholder="Select..."
                value={this.state.company_id}
                maxLength={45}
                asterix={true}
                options={this.state.presets}
                onChange={this.onChange.bind(this)}
                error={this.state.errClient}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <SelectBox 
                name="preset_id"
                label="Leaf Material"
                placeholder="Select..."
                value={this.state.company_id}
                maxLength={45}
                asterix={true}
                options={this.state.presets}
                onChange={this.onChange.bind(this)}
                error={this.state.errClient}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <SelectBox 
                name="preset_id"
                label="Hinges"
                placeholder="Select..."
                value={this.state.company_id}
                maxLength={45}
                asterix={true}
                options={this.state.presets}
                onChange={this.onChange.bind(this)}
                error={this.state.errClient}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <SelectBox 
                name="preset_id"
                label="Lip Type"
                placeholder="Select..."
                value={this.state.company_id}
                maxLength={45}
                asterix={true}
                options={this.state.presets}
                onChange={this.onChange.bind(this)}
                error={this.state.errClient}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <SelectBox 
                name="preset_id"
                label="Frame Type"
                placeholder="Select..."
                value={this.state.company_id}
                maxLength={45}
                asterix={true}
                options={this.state.presets}
                onChange={this.onChange.bind(this)}
                error={this.state.errClient}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <SelectBox 
                name="finish"
                label="Finish"
                placeholder="Select..."
                value={this.state.finish}
                maxLength={45}
                asterix={true}
                options={this.state.finish_products}
                onChange={this.onChange.bind(this)}
                error={this.state.errFinish}
            />
          </div>
        </div>
</div>
      */}

                    </div>

                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <SubmitButton
                        text="Save"
                        onClick={this.save}
                      />
                    </div>

                  </div>
                }





              </div>
              {/* end content block */}
            </div>

          </main>
        </div>
      </div >

    )

  }

}

QuoteItemPage.propTypes = {
  firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    firstName: state.account.firstName,
  }
};

export default connect(mapStateToProps)(QuoteItemPage);