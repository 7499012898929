import React from 'react';
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { connect } from 'react-redux';
import { isPrimitive } from 'util';
import PropTypes from 'prop-types';
import HomePage from '../containers/Home';
import QuotesPage from '../containers/Quotes';
import QuotePage from '../containers/Quote';
import QuoteEditPage from '../containers/QuoteEdit';
import QuoteItemPage from '../containers/QuoteItem';
import ClientsPage from '../containers/Clients';
import ClientPage from '../containers/Client';
import ClientEditPage from '../containers/ClientEdit';
import ContractorsPage from '../containers/Contractors';
import ContractorPage from '../containers/Contractor';
import ContractorEditPage from '../containers/ContractorEdit';
import ReportsPage from '../containers/Reports';
import LoginPage from '../containers/Login';
import SettingsPage from '../containers/Settings';
import UsersPage from '../containers/Users';
import UserPage from '../containers/User';
import UserEditPage from '../containers/UserEdit';
import ContactPage from '../containers/Contact';
import ContactEditPage from '../containers/ContactEdit';
import PricingPage from '../containers/Pricing';
import PricingGroupPage from '../containers/PricingGroup';
import PricingOptionPage from '../containers/PricingOption';
import ProductsPage from '../containers/Products';
import TodayPage from '../containers/Today';
import WebsitesPage from '../containers/Websites';
import WebsitePage from '../containers/Website';
import WebsiteEditPage from '../containers/WebsiteEdit';

class Routes extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
        localStorage.getItem("hmgUserData") === null
          ? <Redirect to='/login' />
          : <Component {...props} />
      )} />
    )

    return (
      <Switch>
        <PrivateRoute exact path="/" component={HomePage} />
        <Route exact path="/login" component={LoginPage} />
        <PrivateRoute exact path="/quotes" component={QuotesPage} />
        <PrivateRoute exact path="/quotes/:id" component={QuotePage} />
        <PrivateRoute exact path="/quotes/edit/:id" component={QuoteEditPage} />
        <PrivateRoute exact path="/quotes/:quote/:id" component={QuoteItemPage} />
        <PrivateRoute exact path="/clients" component={ClientsPage} />
        <PrivateRoute exact path="/clients/:id" component={ClientPage} />
        <PrivateRoute exact path="/clients/edit/:id" component={ClientEditPage} />
        <PrivateRoute exact path="/contractors" component={ContractorsPage} />
        <PrivateRoute exact path="/contractors/:id" component={ContractorPage} />
        <PrivateRoute exact path="/contractors/edit/:id" component={ContractorEditPage} />
        <PrivateRoute exact path="/contacts/:id" component={ContactPage} />
        <PrivateRoute exact path="/contacts/edit/:id" component={ContactEditPage} />
        <PrivateRoute exact path="/websites" component={WebsitesPage} />
        <PrivateRoute exact path="/reports" component={ReportsPage} />
        <PrivateRoute exact path="/settings" component={SettingsPage} />
        <PrivateRoute exact path="/users" component={UsersPage} />
        <PrivateRoute exact path="/users/:id" component={UserPage} />
        <PrivateRoute exact path="/users/edit/:id" component={UserEditPage} />
        <PrivateRoute exact path="/products" component={ProductsPage} />
        <PrivateRoute exact path="/pricing" component={PricingPage} />
        <PrivateRoute exact path="/pricing/:id" component={PricingGroupPage} />
        <PrivateRoute exact path="/pricing/:group/:id" component={PricingOptionPage} />
        <PrivateRoute exact path="/websites" component={WebsitesPage} />
        <PrivateRoute exact path="/websites/:id" component={WebsitePage} />
        <PrivateRoute exact path="/websites/edit/:id" component={WebsiteEditPage} />
        <PrivateRoute exact path="/today" component={TodayPage} />
      </Switch>
    )
  }

};

Routes.propTypes = {
  isLoggedIn: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.account.isLoggedIn,
  }
};

export default connect(mapStateToProps)(Routes);