import sdLib from './libs/sdLib';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import "./App.css";
import SiteNav from './components/SiteNav';
import { Header } from './components/Header';
import MobileMenu from './components/MobileMenu';
import auth from './libs/auth';
import Routes from './config/routes';
import { Menu } from './components/Menu';

class App extends Component {

  render() {

    //const loggedIn = localStorage.getItem("userData") === null ? false : true;
    const loggedIn = true; // TEMP

    return (

      <Routes {...this.props} />

      /*
      <div>
        {loggedIn &&
        <Header {...this.props} />
        }
        <main>
          {loggedIn &&
          <div className="left-column">
            <Menu {...this.props} />
          </div>
          }
          <div className={loggedIn ? 'right-column' : `login-screen`}>
            <Routes {...this.props} />
          </div>
        </main>
      </div>
      */
    )
  }

}

App.propTypes = {
  isLoggedIn: PropTypes.bool,
  userName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
      isLoggedIn: state.account.isLoggedIn,
      userName: state.account.userName,
  }
};

export default withRouter(connect(mapStateToProps)(App));