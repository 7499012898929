import {
    SET_SESSION,
    SET_USER_DATA,
    SIGN_OUT
} from '../actions/account';

const initialState = {
    userKey: '',
    userName: '',
    firstName: '',
    isLoggedIn: false,
};

const account = (state = initialState, action) => {
    switch (action.type) {
        case SET_SESSION:
            return {
                ...state,
                userKey: action.payload.userKey,
                userName: action.payload.userName,
                firstName: action.payload.firstName,
                isLoggedIn: action.payload.isLoggedIn,
            };
        case SET_USER_DATA:
            return {
                ...state,
                accountType: action.payload.accountType,
            };
        case SIGN_OUT:
            return {
                ...state,
                userKey: '',
                userName: '',
                firstName: '',
                isLoggedIn: false,
            }
        default:
            return state
    }
}

export default account