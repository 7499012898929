export default(state = [], payload) => {
    switch(payload.type) {
        case 'add':
            return [...state, payload.item];
        case 'del':
            console.log(...state);
            return [...state.cart.slice(0, payload), ...state.cart.slice(payload + 1)]
        default:
            return state;
    }
};