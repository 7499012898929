import apiRequest from '.';
import APIUtils from './APIUtils';

class AuditAPI {

    getAuditLog(options = {}, cb) {
        let url = "audit?1=1";
        if (options.company) {
            url += `&company=${options.company}`;
        }
        if (options.contact) {
            url += `&contact=${options.contact}`;
        }
        if (options.user) {
            url += `&user=${options.user}`;
        }

        console.log(url);

        apiRequest({ endpoint: url }, cb);
    }

}

export default new AuditAPI();