import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import UserAPI from '../../api/UserAPI';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import { Tasks } from '../../components/Tasks';

class HomePage extends Component {

  constructor() {
    super();
    this.state = {
      id: '',
      firstname: '',
      tasks: 0,
      today: 0,
      week: 0,
    }

  }

  componentDidMount() {
    UserAPI.getUserData((data) => {
      console.log(data);
      this.setState({
        id: data._id,
        firstname: data.firstname,
      }, () => {
        this.loadUserStats();
      });
    })
  }

  loadUserStats() {
    UserAPI.getStats(this.state.id, (data) => {
      console.log("user stats", data);
      this.setState({
        tasks: data.tasks,
        today: data.today,
        week: data.week,
      });
    });
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  render() {

    return (
      <div className="h-screen flex overflow-hidden bg-cool-gray-100">

        <Menu {...this.props} />
        <MobileMenu {...this.props} />

        <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">

          <Header {...this.props} />

          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <img className="hidden h-15 w-15 rounded-full sm:block" src={`/assets/img/users/${this.state.id}.jpg`} alt="" />
                      <div>
                        <div className="flex items-center">
                          <img className="h-15 w-15 rounded-full sm:hidden" src={`/assets/img/users/${this.state.id}.jpg`} alt="" />
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                            {`${SharedFunctions.getGreetingTime(moment())}, ${this.state.firstname}`}
                          </h1>
                        </div>
                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                          <dt className="sr-only">Company</dt>
                          <dd className="flex items-center text-sm leading-5 text-cool-gray-500 font-medium capitalize sm:mr-6">
                            <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd" />
                            </svg>
                                Howard Mitchell
                              </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    &nbsp;
                      </div>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Last 30 days
  </h3>
                  <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                                Outstanding Tasks
              </dt>
                              <dd className="flex items-baseline">
                                <div className="text-2xl leading-8 font-semibold text-gray-900">
                                  {this.state.tasks}
                                </div>
                                <div className="ml-2 flex items-baseline text-sm leading-5 font-semibold text-green-600">
                                  &nbsp;
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-4 sm:px-6">
                        <div className="text-sm leading-5">
                          <a href="/today" className="font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                            View all
          </a>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                            </svg>
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                                Tasks Due Today
              </dt>
                              <dd className="flex items-baseline">
                                <div className="text-2xl leading-8 font-semibold text-gray-900">
                                  {this.state.today}
                                </div>
                                <div className="ml-2 flex items-baseline text-sm leading-5 font-semibold text-green-600">
                                  &nbsp;
                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-4 sm:px-6">
                        <div className="text-sm leading-5">
                          <a href="/today" className="font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                            View all
          </a>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                            </svg>
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                                Tasks Due This Week
              </dt>
                              <dd className="flex items-baseline">
                                <div className="text-2xl leading-8 font-semibold text-gray-900">
                                  {this.state.week}
                                </div>
                                <div className="ml-2 flex items-baseline text-sm leading-5 font-semibold text-red-600">
                                  &nbsp;
                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-4 sm:px-6">
                        <div className="text-sm leading-5">
                          <a href="/today" className="font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                            View all
          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="hidden sm:block">
                  <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                  </div>
                </div>

                <Tasks assigned={true} complete={"false"} title={"My Open Tasks"} />

              </div>

            </div>
          </main>
        </div>
      </div>

    )

  }

}

HomePage.propTypes = {
  firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    firstName: state.account.firstName,
  }
};

export default connect(mapStateToProps)(HomePage);