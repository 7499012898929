import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { signOut } from '../../actions/account';
import UserAPI from '../../api/UserAPI';
import QuoteAPI from '../../api/QuoteAPI';
import { TextInput, TextArea } from '../../components/TextInput';
import { SubmitButton } from '../../components/Button';
import moment from 'moment';

class Quotes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            contractor: this.props.contractor,
            quotes: [],
        }

    }

    componentDidMount() {
        this.loadQuotes();
    }

    loadQuotes() {

        let params = {};

        if (this.state.id) {
            params.company = this.state.id;
        }

        if (this.state.contractor) {
            params.contractor = this.state.contractor;
        }

        QuoteAPI.getQuotes(params, (data) => {
            console.log("quotes", data);
            this.setState({
                quotes: data,
            });
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    render() {
        return (

            <div>
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">


                    <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap border-b border-gray-200 sm:flex-no-wrap px-4 py-5 sm:px-6">
                        <div className="ml-4 mt-4">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {this.state.contractor ? 'Jobs' : 'Quotes'}
                            </h3>
                        </div>
                        <div className="ml-4 mt-4 flex-shrink-0">
                            {this.state.id &&
                                <span className="inline-flex rounded-md shadow-sm">
                                    <a href={this.props.link} className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline-teal focus:border-teal-700 active:bg-teal-700">
                                        Create new quote
                                </a>
                                </span>
                            }
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead>
                                            <tr>
                                                <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="10%">
                                                    Reference
                                    </th>
                                                <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="60%">
                                                    Project / Client
                                    </th>
                                                <th className="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="10%">
                                                    Amount
                                    </th>
                                                <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="20%">
                                                    Status
                                    </th>
                                                <th className="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="10%">
                                                    Date
                                    </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {this.state.quotes.length === 0 &&
                                                <tr>
                                                    <td colSpan="5" className="px-6 py-4 whitespace-no-wrap">
                                                        <div className="text-sm leading-5 text-cool-gray-500 text-center">
                                                            No related quotes added.
                                                </div>
                                                    </td>
                                                </tr>
                                            }
                                            {this.state.quotes.map((item, index) =>
                                                <tr key={index} className="bg-white">
                                                    <td className="x-6 px-6 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                                        <a href={`/quotes/${item._id}`} className="group inline-flex space-x-2 truncate text-sm leading-5">
                                                            <p className="text-cool-gray-500 truncate group-hover:text-cool-gray-900 transition ease-in-out duration-150">
                                                                #HMG{item.quoteNumber}
                                                            </p>
                                                        </a>
                                                    </td>
                                                    <td className="max-w-0 px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-900">
                                                        <div className="text-sm font-medium text-gray-900">
                                                            {item.name}
                                                        </div>
                                                        <div className="text-sm text-gray-500">
                                                            {item.company_id.name}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 text-right whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                                        <span className="text-cool-gray-900 font-medium">&pound;{(parseFloat(item.price) + (((parseFloat(item.price) / 100) * parseFloat(item.discount) * -1)) + ((parseFloat(item.price) - ((parseFloat(item.price) / 100) * parseFloat(item.discount))) * 0.2)).toFixed(2)} </span>
                                    GBP
                                </td>
                                                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800 capitalize">
                                                            {item.status === 'enquiry' ? 'Enquiry' :
                                                                item.status === 'rfq_sent' ? 'RFQ Sent to Manufacturer' :
                                                                    item.status === 'quote_sent' ? 'Quote Sent' :
                                                                        item.status === 'quote_revised' ? 'Quote Revised' :
                                                                            item.status === 'invoice_raised' ? 'Invoice Raised' :
                                                                                item.status === 'payment_received' ? 'Deposit / Full Payment Received' :
                                                                                    item.status === 'visit_arranged' ? 'Site Visit Arranged' :
                                                                                        item.status === 'visit_revision' ? 'Site Visit Revision' :
                                                                                            item.status === 'delivery_date' ? 'Delivery Date Received' :
                                                                                                item.status === 'install_arranged' ? 'Installation Date Arranged' :
                                                                                                    item.status === 'install_complete' ? 'Installation Complete' :
                                                                                                        item.status === 'install_payment' ? 'Installation Payment Received' :
                                                                                                            item.status === 'follow_up' ? 'Courtesy Follow Up' :
                                                                                                                'Unknown'}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 text-right whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                                        {moment(item.created).format("MMM DD, YYYY")}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )
    };

}

Quotes.propTypes = {
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
        userName: state.account.userName,
    }
};

export default connect(mapStateToProps)(Quotes);