import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Title } from '../../components/Title';
import { TodaysMetrics } from '../../components/MetricSidebar';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import CompanyAPI from '../../api/CompanyAPI';
import ContactAPI from '../../api/ContactAPI';
import * as QueryString from "query-string";
import WebsiteAPI from '../../api/WebsiteAPI';

class WebsiteEditPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      domain: '',
      location: '',
    }

    this.save = this.save.bind(this);

  }

  componentDidMount() {
    if (this.state.id !== 'new') {
      this.loadWebsite();
    }
  }

  loadWebsite() {
    WebsiteAPI.getWebsite(this.state.id, (data) => {
      this.setState({
        domain: data.domain,
        location: data.location,
      });
    })
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
      errDomain: '',
    });
  }

  save() {

    var _ok = true;

    if (this.state.domain === "") {
      _ok = false;
      this.setState({
        errDomain: 'Please enter the domain name'
      });
    }

    if (_ok) {

      let payload = {
        domain: this.state.domain,
        location: this.state.location,
      }

      console.log(payload);

      if (this.state.id === 'new') {
        WebsiteAPI.addWebsite(payload, (data) => {
          this.props.history.push(`/websites/${data._id}`);
        });
      } else {
        WebsiteAPI.updWebsite(this.state.id, payload, (data) => {
          this.props.history.push(`/websites/${this.state.id}`);
        });
      }

    }

  }

  render() {

    return (
      <div className="h-screen flex overflow-hidden bg-cool-gray-100">

        <Menu {...this.props} />
        <MobileMenu {...this.props} />

        <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">

          <Header {...this.props} />

          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

            {/* begin page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                            Website
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
            {/* end page header */}

            <div className="mt-8">
              {/* begin content block */}
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

                <div className="mt-10 sm:mt-0">
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Website Details</h3>
                        <p className="mt-1 text-sm leading-5 text-gray-600">
                          The website details.
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <TextInput
                                name="domain"
                                type="text"
                                label="Domain name"
                                value={this.state.domain}
                                maxLength={250}
                                asterix={true}
                                onChange={this.onChange.bind(this)}
                                error={this.state.errDomain}
                              />
                            </div>

                          </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <SubmitButton
                            text="Save"
                            onClick={this.save}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hidden sm:block">
                  <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                  </div>
                </div>

                <div className="mt-10 sm:mt-0">
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Content Settings</h3>
                        <p className="mt-1 text-sm leading-5 text-gray-600">
                          Configure the website content.
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="grid grid-cols-6 gap-6">

                            <div className="col-span-6 sm:col-span-3">
                              <TextInput
                                name="location"
                                type="text"
                                label="Location name"
                                value={this.state.location}
                                maxLength={250}
                                asterix={true}
                                onChange={this.onChange.bind(this)}
                              />
                            </div>

                          </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <SubmitButton
                            text="Save"
                            onClick={this.save}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              {/* end content block */}
            </div>

          </main>
        </div>
      </div>

    )

  }

}

WebsiteEditPage.propTypes = {
  firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    firstName: state.account.firstName,
  }
};

export default connect(mapStateToProps)(WebsiteEditPage);