import apiRequest from '.';
import APIUtils from './APIUtils';

class ContactAPI {

    getContacts(params, cb) {
        let url = 'contact?1=1';
        if (params.company) {
            url += `&company=${params.company}`;
        }
        apiRequest({ endpoint: url }, cb);
    }

    getContact(id, cb) {
        apiRequest({ endpoint: `contact/${id}` }, cb);
    }

    addContact(payload, cb) {
        apiRequest({ endpoint: `contact`, method: 'POST', payload }, cb);
    }

    updContact(id, payload, cb) {
        apiRequest({ endpoint: `contact/${id}`, method: 'PUT', payload }, cb);
    }

    delContact(id, cb) {
        apiRequest({ endpoint: `contact/${id}`, method: 'DELETE' }, cb);
    }

}

export default new ContactAPI();