import apiRequest from '.';
import APIUtils from './APIUtils';

class WebsiteAPI {

  getWebsites(cb) {
    let url = 'website';
    apiRequest({ endpoint: url }, cb);
  }

  getWebsite(id, cb) {
    apiRequest({ endpoint: `website/${id}` }, cb);
  }

  addWebsite(payload, cb) {
    apiRequest({ endpoint: `website`, method: 'POST', payload }, cb);
  }

  updWebsite(id, payload, cb) {
    apiRequest({ endpoint: `website/${id}`, method: 'PUT', payload }, cb);
  }

}

export default new WebsiteAPI();