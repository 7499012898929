import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Title } from '../../components/Title';
import { TodaysMetrics } from '../../components/MetricSidebar';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import { SelectBox } from '../../components/SelectBox';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import CompanyAPI from '../../api/CompanyAPI';
import PricingAPI from '../../api/PricingAPI';
import ProductAPI from '../../api/ProductAPI';

class PricingOptionPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      group: props.match.params.group,
      name: '',
      price: 0,
      rules: [],
      rule_product: '',
      rule_group: '',
      rule_condition: '',
      errRuleCondition: '',
      errRuleGroup: '',
      errRuleProduct: '',
      pricing: [],
      groups: [],
      products: [],
      conditions: [
        {
          value: "IS",
          label: "IS"
        }, {
          value: "IS NOT",
          label: "IS NOT"
        }
      ]
    }

    this.save = this.save.bind(this);
    this.deleteOption = this.deleteOption.bind(this);
    this.addRule = this.addRule.bind(this);
    this.delRule = this.delRule.bind(this);

  }

  componentDidMount() {
    this.loadPricing();
    if (this.state.id !== 'new') {
      this.loadOption();
    }
  }

  loadOption() {
    PricingAPI.getOption(this.state.id, (data) => {
      this.setState({
        name: data.name,
        price: data.price,
        rules: data.rules
      });
    })
  }

  loadPricing() {
    PricingAPI.getProducts('', (data) => {
      let _groups = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].id !== this.state.group) {
          _groups.push({
            value: data[i].id,
            label: data[i].name,
          });
        }
      }
      this.setState({
        pricing: data,
        groups: _groups
      });
    })
  }

  onChange(name, value) {

    if (name === 'rule_group') {
      for (var i = 0; i < this.state.pricing.length; i++) {
        if (this.state.pricing[i].id === value) {
          let _products = [];
          let _options = this.state.pricing[i].options;
          console.log("options", _options);
          for (var ix = 0; ix < _options.length; ix++) {
            _products.push({
              value: _options[ix].id,
              label: _options[ix].name,
            });
          }
          this.setState({
            products: _products
          });
        }
      }
    }

    this.setState({
      [name]: value,
      errRuleCondition: '',
      errRuleGroup: '',
      errRuleProduct: '',
    });
  }

  deleteOption() {
    PricingAPI.delOption(this.state.id, (data) => {
      this.props.history.push('/pricing');
    });
  }

  save() {

    let payload = {
      group_id: this.state.group,
      name: this.state.name,
      price: this.state.price,
      rules: this.state.rules
    }

    if (this.state.id === 'new') {
      PricingAPI.addOption(payload, (data) => {
        console.log("addOption()", data);
        this.props.history.push('/pricing');
      });
    } else {
      PricingAPI.updOption(this.state.id, payload, (data) => {
        console.log("updOption()", data);
        this.props.history.push('/pricing');
      });
    }
  }

  addRule() {

    let _ok = true;

    if (this.state.rule_group === '') {
      _ok = false;
      this.setState({
        errRuleGroup: 'Please select a group'
      });
    }
    if (this.state.rule_product === '') {
      _ok = false;
      this.setState({
        errRuleProduct: 'Please select an option'
      });
    }
    if (this.state.rule_condition === '') {
      _ok = false;
      this.setState({
        errRuleCondition: 'Please select a condition'
      });
    }

    if (_ok) {

      let _rule_group_name = '';
      let _rule_product_name = '';

      PricingAPI.getGroup(this.state.rule_group, (data) => {
        _rule_group_name = data.name;
        PricingAPI.getOption(this.state.rule_product, (datax) => {
          _rule_product_name = datax.name;
          let _rule = {
            group_id: this.state.rule_group,
            product_id: this.state.rule_product,
            condition: this.state.rule_condition,
            group_name: _rule_group_name,
            product_name: _rule_product_name,
          };

          this.setState({
            rules: this.state.rules.concat([_rule]),
            rule_group: '',
            rule_product: '',
            rule_condition: '',
          });
        });
      });



    }
  }

  delRule(_index) {
    this.setState({
      rules: this.state.rules.filter((item, index) => index !== _index)
    });
  }

  render() {

    return (
      <div className="h-screen flex overflow-hidden bg-cool-gray-100">

        <Menu {...this.props} />
        <MobileMenu {...this.props} />

        <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">

          <Header {...this.props} />

          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

            {/* begin page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                            Pricing Option
                              </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    &nbsp;
                      </div>
                </div>
              </div>
            </div>
            {/* end page header */}

            <div className="mt-8">
              {/* begin content block */}
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="bg-white rounded-md shadow px-6 flex space-x-4 mb-8">
                    <li className="flex">
                      <div className="flex items-center">
                        <a href="/" className="text-gray-400 hover:text-gray-500">
                          <svg className="flex-shrink-0 h-5 w-5 transition duration-150 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                          </svg>
                        </a>
                        <span className="sr-only">Home</span>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center space-x-4">
                        <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <a href="/pricing" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Pricing</a>
                      </div>
                    </li>
                  </ol>
                </nav>

                <div className="mt-10 sm:mt-0">
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Option Information</h3>
                        <p className="mt-1 text-sm leading-5 text-gray-600">
                          The option details.
                            </p>
                      </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <TextInput
                                name="name"
                                type="text"
                                label="Option name"
                                value={this.state.name}
                                maxLength={85}
                                asterix={true}
                                onChange={this.onChange.bind(this)}
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <TextInput
                                name="price"
                                type="number"
                                label="Price"
                                value={this.state.price}
                                maxLength={85}
                                asterix={true}
                                onChange={this.onChange.bind(this)}
                              />
                            </div>

                          </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              {this.state.id !== 'new' &&
                                <svg className="delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this option?')) this.deleteOption() }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                              }
                            </div>
                            <div className="col-span-6 sm:col-span-3 text-right">
                              <SubmitButton
                                text="Save"
                                onClick={this.save}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hidden sm:block">
                  <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                  </div>
                </div>

                <div className="mt-10 sm:mt-0">
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Conditions</h3>
                        <p className="mt-1 text-sm leading-5 text-gray-600">
                          The option conditions.
                            </p>
                      </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-12">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="30%">Group</th>
                                    <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="30%">Condition</th>
                                    <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="35%">Value</th>
                                    <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="5%">&nbsp;</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.rules.length === 0 &&
                                    <tr>
                                      <td colSpan="4" className="px-6 py-4 whitespace-no-wrap">
                                        <div className="text-sm leading-5 text-cool-gray-500 text-center">
                                          No rules added.
                                                </div>
                                      </td>
                                    </tr>
                                  }
                                  {this.state.rules.map((rule, irule) =>
                                    <tr key={irule} className="bg-white">
                                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                        {rule.group_name}
                                      </td>
                                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                        {rule.condition}
                                      </td>
                                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                        {rule.product_name}
                                      </td>
                                      <td className="px-6 py-4 whitespace-no-wrap text-sm text-right leading-5 text-cool-gray-500">
                                        <svg className="delete-button" style={{ marginTop: 0 }} onClick={() => { if (window.confirm('Are you sure you want to delete this rule?')) this.delRule(irule) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                        </svg>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              <hr className="mt-5 mb-5" />
                            </div>
                          </div>
                          <div className="grid grid-cols-3 gap-6">
                            <div className="col-span-3 sm:col-span-1">
                              <SelectBox
                                name="rule_group"
                                label="Group"
                                placeholder="Select..."
                                value={this.state.rule_group}
                                maxLength={45}
                                asterix={true}
                                options={this.state.groups}
                                onChange={this.onChange.bind(this)}
                                error={this.state.errRuleGroup}
                              />
                            </div>
                            <div className="col-span-3 sm:col-span-1">
                              <SelectBox
                                name="rule_condition"
                                label="Condition"
                                placeholder="Select..."
                                value={this.state.rule_condition}
                                maxLength={45}
                                asterix={true}
                                options={this.state.conditions}
                                onChange={this.onChange.bind(this)}
                                error={this.state.errRuleCondition}
                              />
                            </div>
                            <div className="col-span-3 sm:col-span-1">
                              <SelectBox
                                name="rule_product"
                                label="Product"
                                placeholder="Select..."
                                value={this.state.rule_product}
                                maxLength={45}
                                asterix={true}
                                options={this.state.products}
                                disabled={this.state.products.length === 0}
                                onChange={this.onChange.bind(this)}
                                error={this.state.errRuleProduct}
                              />
                            </div>

                          </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              &nbsp;
                            </div>
                            <div className="col-span-6 sm:col-span-3 text-right">
                              <SubmitButton
                                text="Add"
                                onClick={this.addRule}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              {/* end content block */}
            </div>

          </main>
        </div>
      </div>

    )

  }

}

PricingOptionPage.propTypes = {
  firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    firstName: state.account.firstName,
  }
};

export default connect(mapStateToProps)(PricingOptionPage);