import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class TextInput extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.url && this.props.button) {
            console.warn('Both a url and button prop were passed to the TextInput component');
        }
    }

    onFieldChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.onChange(name, value);
    }

    validateInput(e) {
        if (this.props.pattern) {
            const re = RegExp(this.props.pattern);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }

        if (this.props.alhpaNumeric) {
            const re = RegExp(/[0-9A-Za-z]+/g);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }
    }

    render() {
        return (
            <div>
                {this.props.label ? <label className="block text-sm font-medium leading-5 text-gray-700" htmlFor={this.props.name}>{this.props.label}{this.props.asterix ? <span className="asterix">*</span> : ''}</label> : ''}
                {this.props.tip ? <div className="tip">{this.props.tip}</div>: ''}
                <input
                    id={this.props.name}
                    type={this.props.type ? this.props.type : "text"}
                    name={`${this.props.name}`}
                    placeholder={this.props.placeholder}
                    onChange={this.onFieldChange.bind(this)}
                    maxLength={this.props.maxLength ? this.props.maxLength : 200}
                    value={this.props.toUpper && this.props.value ? this.props.value.toUpperCase() : this.props.value}
                    className={`mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${this.props.disabled ? `disabled-input` : ''}`}
                    onKeyPress={(e) => this.validateInput(e)}
                    autoComplete={`auto-${this.props.name}`}
                    pattern={this.props.pattern ? this.props.pattern : null}
                    disabled={this.props.disabled ? this.props.disabled : false}
                    step={0.01}
                    min={0}
                />
                {this.props.error &&
                <div className="input-error">
                    <span>{this.props.error}</span>
                </div>
                }
            </div>
        )
    }

};

TextInput.propTypes = {
    id: PropTypes.string.isRequired,
    alhpaNumeric: PropTypes.bool,
    button: PropTypes.func,
    url: PropTypes.string,
    error: PropTypes.string,
    link: PropTypes.string,
    pattern: PropTypes.instanceOf(RegExp),
    class: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    maxLength: PropTypes.number,
    toUpper: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default TextInput;