import apiRequest from '.';
import APIUtils from './APIUtils';

class NoteAPI {

    getNotes(id, cb) {
        apiRequest({ endpoint: `note/${id}` }, cb);
    }

    addNote(payload, cb) {
        apiRequest({ endpoint: `note`, method: 'POST', payload }, cb);
    }

}

export default new NoteAPI();