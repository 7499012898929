import apiRequest from '.';
import APIUtils from './APIUtils';

class CompanyAPI {

    getCompanies(type, cb) {
        apiRequest({ endpoint: `company?type=${type}` }, cb);
    }

    getCompany(id, cb) {
        apiRequest({ endpoint: `company/${id}` }, cb);
    }

    addCompany(payload, cb) {
        apiRequest({ endpoint: `company`, method: 'POST', payload }, cb);
    }

    updCompany(id, payload, cb) {
        apiRequest({ endpoint: `company/${id}`, method: 'PUT', payload }, cb);
    }

    delCompany(id, cb) {
        apiRequest({ endpoint: `company/${id}`, method: 'DELETE' }, cb);
    }

}

export default new CompanyAPI();