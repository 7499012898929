import apiRequest from '.';
import APIUtils from './APIUtils';

class SearchAPI {

    getSearch(keyword, cb) {
        let url = `search?keyword=${keyword}`;
        apiRequest({ endpoint: url }, cb);
    }

}

export default new SearchAPI();