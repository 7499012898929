import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { signOut } from '../../actions/account';
import UserAPI from '../../api/UserAPI';
import { SubmitButton } from '../../components/Button';
import SearchAPI from '../../api/SearchAPI';

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: '',
      firstname: '',
      lastname: '',
      email: '',
      keyword: '',
      results: [],
      tasks: 0,
    }

    this.goTo = this.goTo.bind(this);
    this.showNotifications = this.showNotifications.bind(this);

  }

  componentDidMount() {
    UserAPI.getUserData((data) => {
      console.log(data);
      this.setState({
        id: data._id,
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email
      }, () => {
        this.loadUserStats();
      });
    })
  }

  loadUserStats() {
    UserAPI.getStats(this.state.id, (data) => {
      console.log("stats", data);
      this.setState({
        tasks: data.tasks
      });
    });
  }

  goTo(link) {
    this.setState({
      keyword: '',
      results: []
    }, () => {
      this.props.history.push(link);
    });
  }

  logOut() {
    this.props.dispatch(signOut());
    localStorage.removeItem('hmgUserData');
    this.props.history.push('/');
  }

  showNotifications() {
    this.props.history.push(`/today`);
  }

  onFieldChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      keyword: value
    }, () => {
      if (value.length >= 2) {
        SearchAPI.getSearch(value, (data) => {
          this.setState({
            results: data
          });
        });
      } else {
        this.setState({
          results: []
        })
      }
    });
  }

  render() {
    return (

      <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
        <button className="px-4 border-r border-cool-gray-200 text-cool-gray-400 focus:outline-none focus:bg-cool-gray-100 focus:text-cool-gray-600 lg:hidden" aria-label="Open sidebar">
          <svg className="h-6 w-6 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
          </svg>
        </button>
        <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="flex-1 flex">
            <form className="w-full flex md:ml-0" action="#" method="GET">
              <label htmlFor="search_field" className="sr-only">Search</label>
              <div className="relative w-full text-cool-gray-400 focus-within:text-cool-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                  <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
                  </svg>
                </div>
                <input id="search_field" autoComplete="off" className="block w-full h-full pl-8 pr-3 py-2 rounded-md text-cool-gray-900 placeholder-cool-gray-500 focus:outline-none focus:placeholder-cool-gray-400 sm:text-sm" placeholder="Search" type="search" value={this.state.keyword} onChange={this.onFieldChange.bind(this)} />
                {this.state.results.length > 0 &&
                  <div className="results">
                    <div className="results-top">Search Results</div>
                    <div className="results-list">
                      {this.state.results.map((item, index) =>
                        <a href={item.url}>
                          <div key={index} className="list-item">
                            <h4>{item.title}</h4>
                            <p>{item.subtitle}</p>
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                }
              </div>
            </form>
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            <button onClick={() => this.showNotifications()} className="p-1 text-cool-gray-400 rounded-full relative hover:bg-cool-gray-100 hover:text-cool-gray-500 focus:outline-none focus:shadow-outline focus:text-cool-gray-500" aria-label="Notifications">
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
              </svg>
              <span className="absolute inset-0 object-right-top -mr-6">
                <div className="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                  {this.state.tasks}
                </div>
              </span>
            </button>

            <div className="ml-3 relative">
              <div>
                <button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:bg-cool-gray-100 lg:p-2 lg:rounded-md lg:hover:bg-cool-gray-100" id="user-menu" aria-label="User menu" aria-haspopup="true">
                  <img className="h-8 w-8 rounded-full" src={`/assets/img/users/${this.state.id}.jpg`} alt="" />
                  <p className="hidden ml-3 text-cool-gray-700 text-sm leading-5 font-medium lg:block">{this.state.firstname} {this.state.lastname}</p>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  };

}

Header.propTypes = {
  isLoggedIn: PropTypes.bool,
  userName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.account.isLoggedIn,
    userName: state.account.userName,
  }
};

export default connect(mapStateToProps)(Header);