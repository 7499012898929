import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import { SubmitButton, SwitchButton } from '../../components/Button';
import PricingAPI from '../../api/PricingAPI';
import ProductAPI from '../../api/ProductAPI';
import { SelectBox } from '../../components/SelectBox';
import { TextArea, TextInput } from '../../components/TextInput';
import axios from 'axios';

class ProductsPage extends Component {

  constructor() {
    super();
    this.state = {
      keyword: '',
      products: [],
      product_id: '',
      product_group: 0,
      name: '',
      price: 0,
      min_items: 0,
      max_items: 0,
      filter_group: 1,
      productOptions: [
        {
          value: 1,
          label: 'General Purpose Single Door'
        }, {
          value: 2,
          label: 'General Purpose Double Door'
        }, {
          value: 3,
          label: 'Fire Exit Single'
        }, {
          value: 4,
          label: 'Fire Exit Double'
        }, {
          value: 5,
          label: 'Fire Rated Single Door'
        }, {
          value: 6,
          label: 'Fire Rated Double Door'
        }, {
          value: 7,
          label: 'Fire Exit Single (Fire Rated)'
        }, {
          value: 8,
          label: 'Fire Exit Double (Fire Rated)'
        }, {
          value: 99,
          label: 'Shared Products'
        }
      ],
      modal: false,
      cost_price: 0,
      width_from: 0,
      width_to: 0,
      height_from: 0,
      height_to: 0,
      notes: '',
      sku: '',
    }

    this.editProduct = this.editProduct.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.onSelectCSV = this.onSelectCSV.bind(this);

  }

  componentDidMount() {
    this.loadProducts();
  }

  loadProducts() {
    ProductAPI.getAllProducts((data) => {
      console.log(data);
      this.setState({
        products: data
      });
    })
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  editProduct(id) {
    ProductAPI.getProduct(id, (data) => {
      this.setState({
        modal: true,
        product_id: id,
        product_group: data.product_group,
        name: data.name,
        price: data.price,
        min_items: data.min_items,
        max_items: data.max_items,
        cost_price: data.cost_price,
        width_from: data.width_from,
        width_to: data.width_to,
        height_from: data.height_from,
        height_to: data.height_to,
        notes: data.notes,
        sku: data.sku,
      });
    });
  }

  addProduct() {
    this.setState({
      modal: true,
      product_id: '',
      product_group: 0,
      name: '',
      price: 0,
      min_items: 0,
      max_items: 0,
      cost_price: 0,
      width_from: 0,
      width_to: 0,
      height_from: 0,
      height_to: 0,
      notes: '',
      sku: '',
    });
  }

  saveProduct() {

    let payload = {
      product_group: parseInt(this.state.product_group),
      name: this.state.name,
      price: parseFloat(this.state.price),
      min_items: this.state.min_items ? parseInt(this.state.min_items) : 0,
      max_items: this.state.max_items ? parseInt(this.state.max_items) : 0,
      cost_price: parseFloat(this.state.cost_price),
      width_from: this.state.width_from ? parseInt(this.state.width_from) : 0,
      width_to: this.state.width_to ? parseInt(this.state.width_to) : 0,
      height_from: this.state.height_from ? parseInt(this.state.height_from) : 0,
      height_to: this.state.height_to ? parseInt(this.state.height_to) : 0,
      notes: this.state.notes,
      sku: this.state.sku,
    }

    if (this.state.product_id === '') {
      ProductAPI.addProduct(payload, (data) => {
        this.setState({
          modal: false,
          product_id: '',
          product_group: 0,
          name: '',
          price: 0,
          min_items: 0,
          max_items: 0,
          cost_price: 0,
          width_from: 0,
          width_to: 0,
          height_from: 0,
          height_to: 0,
          notes: '',
          sku: '',
        });
        this.loadProducts();
      })
    } else {
      ProductAPI.updProduct(this.state.product_id, payload, (data) => {
        this.setState({
          modal: false,
          product_id: '',
          product_group: 0,
          name: '',
          price: 0,
          min_items: 0,
          max_items: 0,
          cost_price: 0,
          width_from: 0,
          width_to: 0,
          height_from: 0,
          height_to: 0,
          notes: '',
          sku: '',

        });
        this.loadProducts();
      });
    }

  }

  deleteProduct(id) {
    ProductAPI.deleteProduct(id, (data) => {
      this.loadProducts();
    });
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  onSelectCSV() {
    console.log("CSV selected");

    const formData = new FormData();
    formData.append("file", this.fileUpload);

    axios
      .post(`https://hmg-api.herokuapp.com/upload`, formData)
      .then((res) => {
        console.log(res.data);
        this.loadProducts();
      })
  }

  render() {

    return (
      <div className="h-screen flex overflow-hidden bg-cool-gray-100">

        <Menu {...this.props} />
        <MobileMenu {...this.props} />

        <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">

          <Header {...this.props} />

          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

            {/* begin page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                            Products
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <span className="shadow-sm rounded-md">
                      <a onClick={() => this.fileUpload.click()} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium cursor-pointer rounded-md text-white bg-teal-600 focus:outline-none focus:shadow-outline-teal transition duration-150 ease-in-out">
                        Upload CSV
                      </a>
                    </span>
                    <span className="shadow-sm rounded-md">
                      <SubmitButton
                        text="Add New Product"
                        onClick={this.addProduct}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* end page header */}

            <div className="mt-8">
              {/* begin content block */}
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">



                <div className="md:mt-0 md:col-span-2 pb-5">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-2 sm:col-span-1">
                      <SelectBox
                        name="filter_group"
                        label=""
                        placeholder="Select Group..."
                        value={this.state.filter_group}
                        maxLength={45}
                        asterix={true}
                        options={this.state.productOptions}
                        onChange={this.onChange.bind(this)}
                      />
                    </div>
                  </div>
                </div>


                <div>
                  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap border-b border-gray-200 sm:flex-no-wrap px-4 py-5 sm:px-6">
                      <div className="ml-4 mt-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Products
                        </h3>
                      </div>
                      <div className="ml-4 mt-4 flex-shrink-0">

                      </div>
                    </div>

                    <div className="flex flex-col">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead>
                                <tr>
                                  <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="10%">
                                    SKU
                                  </th>
                                  <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="50%">
                                    Product
                                  </th>
                                  <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="10%">
                                    Cost Price
                                  </th>
                                  <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="10%">
                                    Price
                                  </th>
                                  <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="10%">
                                    Max Qty
                                  </th>
                                  <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="10%">
                                    &nbsp;
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {this.state.products.filter(c => parseInt(this.state.filter_group) === 0 || parseInt(c.product_group) === parseInt(this.state.filter_group)).map((item, index) =>
                                  <tr key={index} className="bg-white">
                                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                      {item.sku}
                                    </td>
                                    <td className="x-6 px-6 text-sm leading-5 text-cool-gray-500">
                                      <div className="text-gray-900 text-sm leading-5 text-left">
                                        {item.name}
                                      </div>
                                      {item.notes &&
                                        <div className="text-gray-500 text-xs">
                                          {item.notes}
                                        </div>
                                      }
                                      {(parseInt(item.width_from) > 0 || parseInt(item.width_to) > 0 || parseInt(item.height_from) > 0 || parseInt(item.height_to) > 0) &&
                                        <div className="text-gray-500 text-xs">
                                          Width Range: {item.width_from}mm - {item.width_to}mm, Height Range: {item.height_from}mm - {item.height_to}mm
                                        </div>
                                      }
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                      &pound;{item.cost_price}
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                      &pound;{item.price.toFixed(2)}
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                      {item.max_items === 0 ? '-' : item.max_items}
                                    </td>
                                    <td className="px-6 py-4">
                                      <div className="text-sm leading-5 text-gray-900 text-right">
                                        <svg onClick={() => this.editProduct(item._id)} alt="Edit" style={{ width: '16px' }} xmlns="http://www.w3.org/2000/svg" className="inline-block h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>

                                        <svg onClick={() => { if (window.confirm('Are you sure you want to delete this product?')) this.deleteProduct(item._id) }} alt="Delete" style={{ width: '16px' }} className="inline-block ml-4 w-6 h-6 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>

                          </div>

                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="hidden sm:block">
                    <div className="py-5">
                      <div className="border-t border-gray-200"></div>
                    </div>
                  </div>

                </div>




              </div>
              {/* end content block */}
            </div>



            {this.state.modal &&
              <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                  <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">

                    <div className="quote-form">

                      <div className="close">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={this.toggleModal}>
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>

                      <div className="grid grid-cols-4 gap-4 quote-header">
                        <div className="col-span-4 py-2">

                          &nbsp;

                        </div>
                      </div>

                      <div className="grid grid-cols-4 gap-4">

                        <div className="col-span-4">
                          <div className="quote-main">

                            <div className="mt-5 md:mt-0 md:col-span-2 px-4 py-5">
                              <div className="grid grid-cols-4 gap-6">

                                <div className="col-span-1">
                                  <SelectBox
                                    name="product_group"
                                    label="Product Group"
                                    placeholder="Select..."
                                    value={this.state.product_group}
                                    maxLength={45}
                                    asterix={true}
                                    options={this.state.productOptions}
                                    onChange={this.onChange.bind(this)}
                                    error={this.state.errVat}
                                  />
                                </div>

                                <div className="col-span-1">
                                  <TextInput
                                    name="sku"
                                    label="Product SKU"
                                    type="text"
                                    value={this.state.sku}
                                    maxLength={100}
                                    asterix={true}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>

                                <div className="col-span-2">
                                  <TextInput
                                    name="name"
                                    label="Product Name"
                                    type="text"
                                    value={this.state.name}
                                    maxLength={100}
                                    asterix={true}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>

                                <div className="col-span-1">
                                  <TextInput
                                    name="cost_price"
                                    label="Cost Price"
                                    type="number"
                                    value={this.state.cost_price}
                                    maxLength={45}
                                    asterix={true}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>

                                <div className="col-span-1">
                                  <TextInput
                                    name="price"
                                    label="Retail Price"
                                    type="number"
                                    value={this.state.price}
                                    maxLength={45}
                                    asterix={true}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>

                                <div className="col-span-1">
                                  <TextInput
                                    name="min_items"
                                    label="Min Qty"
                                    type="number"
                                    value={this.state.min_items}
                                    maxLength={45}
                                    asterix={true}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>

                                <div className="col-span-1">
                                  <TextInput
                                    name="max_items"
                                    label="Max Qty"
                                    type="number"
                                    value={this.state.max_items}
                                    maxLength={45}
                                    asterix={true}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>

                                <div className="col-span-1">
                                  <TextInput
                                    name="width_from"
                                    label="Min Width"
                                    type="number"
                                    value={this.state.width_from}
                                    maxLength={45}
                                    asterix={true}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>

                                <div className="col-span-1">
                                  <TextInput
                                    name="width_to"
                                    label="Max Width"
                                    type="number"
                                    value={this.state.width_to}
                                    maxLength={45}
                                    asterix={true}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>

                                <div className="col-span-1">
                                  <TextInput
                                    name="height_from"
                                    label="Min Height"
                                    type="number"
                                    value={this.state.height_from}
                                    maxLength={45}
                                    asterix={true}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>

                                <div className="col-span-1">
                                  <TextInput
                                    name="height_to"
                                    label="Max Height"
                                    type="number"
                                    value={this.state.height_to}
                                    maxLength={45}
                                    asterix={true}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>

                                <div className="col-span-4">
                                  <TextArea
                                    name="notes"
                                    label="Notes"
                                    value={this.state.notes}
                                    rows={3}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-4 gap-4 bg-gray-50 quote-footer">
                        <div className="col-span-4">
                          <div className="px-4 py-3 text-right sm:px-6">
                            <SubmitButton
                              text="Save"
                              onClick={() => this.saveProduct()}
                            />
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

            }

            <input type='file' id='csv' ref={(ref) => this.fileUpload = ref} onChange={this.onSelectCSV} style={{ display: 'none' }} />

          </main>
        </div>
      </div>
    )

  }

}

export default ProductsPage;