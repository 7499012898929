import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { signOut } from '../../actions/account';
import UserAPI from '../../api/UserAPI';
import TaskAPI from '../../api/TaskAPI';
import { TextInput, TextArea } from '../../components/TextInput';
import { SubmitButton } from '../../components/Button';
import { SelectBox } from '../../components/SelectBox';
import moment from 'moment';

class Tasks extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            company_id: this.props.company ? this.props.company : '',
            contact_id: this.props.contact ? this.props.contact : '',
            assigned: this.props.assigned ? this.props.assigned : '',
            complete: this.props.complete ? this.props.complete : '',
            today: this.props.today ? this.props.today : '',
            week: this.props.week ? this.props.week : '',
            quote: this.props.quote ? this.props.quote : '',
            author_id: '',
            assigned_id: '',
            company_id: '',
            contact_id: '',
            tasks: [],
            message: '',
            due: '',
            users: [],

        }

        this.addTask = this.addTask.bind(this);

    }

    componentDidMount() {
        this.loadUsers();
        UserAPI.getUserData((data) => {
            this.setState({
                author_id: data._id,
            }, () => {
                this.loadTasks();
            });
        })
    }

    loadUsers() {
        UserAPI.getUsers((data) => {
            console.log()
            var _arr = [];
            for (var i = 0; i < data.length; i++) {
                _arr.push({
                    value: data[i].id,
                    label: data[i].firstname + ' ' + data[i].lastname,
                });
            }
            this.setState({
                users: _arr
            });
        });
    }

    loadTasks() {
        let options = {};
        if (this.props.company) {
            options.company = this.props.company;
        }
        if (this.props.contact) {
            options.contact = this.props.contact;
        }
        if (this.state.assigned) {
            options.assigned = this.state.author_id;
        }
        if (this.state.complete) {
            options.complete = this.state.complete;
        }
        if (this.props.assigned_tasks) {
            options.assigned = this.props.assigned_tasks;
        }
        if (this.state.quote) {
            options.quote = this.props.quote;
        }
        if (this.state.today) {
            options.today = this.state.today;
        }
        if (this.state.week) {
            options.week = this.state.week;
        }

        console.log("options", options);

        TaskAPI.getTasks(options, (data) => {
            console.log("tasks", data);
            this.setState({
                tasks: data,
                message: '',
                assigned_id: '',
                due: '',
            });
        })
    }

    addTask() {
        let payload = {
            ref: this.state.id,
            author_id: this.state.author_id,
            assigned_id: this.state.assigned_id,
            due: this.state.due,
            message: this.state.message
        }

        if (this.props.company) {
            payload.company_id = this.props.company;
        }

        if (this.props.contact) {
            payload.contact_id = this.props.contact;
        }

        if (this.props.quote) {
            payload.quote_id = this.props.quote;
        }

        if (this.state.message !== '') {
            TaskAPI.addTask(payload, (data) => {
                this.loadTasks();
            })
        }
    }

    completeTask(id) {
        console.log("complete task clicked");
        TaskAPI.completeTask(id, (data) => {
            console.log("completeTask, ", data);
            this.loadTasks();
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    render() {
        return (

            <div>
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {this.props.title ? this.props.title : 'Tasks'}
                        </h3>
                    </div>

                    <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead>
                                            <tr>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="30%">
                                                    {(this.props.company || this.props.contact || this.props.quote) ? 'Assigned' : 'Related to'}
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="50%">
                                                    Task
                                    </th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="20%">
                                                    Due
                                    </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {this.state.tasks.length === 0 &&
                                                <tr>
                                                    <td colSpan="23" className="px-6 py-4 whitespace-no-wrap">
                                                        <div className="text-sm leading-5 text-cool-gray-500 text-center">
                                                            No tasks added.
                                                </div>
                                                    </td>
                                                </tr>
                                            }
                                            {this.state.tasks.map((item, index) =>
                                                <tr key={index}>
                                                    <td className="px-6 py-4 whitespace-no-wrap">
                                                        {(this.props.company || this.props.contact || this.props.quote) ?
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0 h-10 w-10">
                                                                    <img className="h-10 w-10 rounded-full" style={item.complete ? { opacity: 0.4 } : { opacity: 1 }} src={`/assets/img/users/${item.assigned_id._id}.jpg`} alt="" />
                                                                </div>
                                                                <div className="ml-4">
                                                                    <div className={item.complete ? `text-sm leading-5 font-medium text-gray-400` : `text-sm leading-5 font-medium text-gray-900`}>
                                                                        {item.assigned_id.firstname} {item.assigned_id.lastname}
                                                                    </div>
                                                                    <div className={item.complete ? `text-sm leading-5 text-gray-400` : `text-sm leading-5 text-gray-500`}>
                                                                        {moment(item.created).format("ddd D MMM YY - HH:mm")}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : item.contact_id ?
                                                                <div className="ml-4">
                                                                    <div className={item.complete ? `text-sm leading-5 font-medium text-gray-400` : `text-sm leading-5 font-medium text-gray-900`}>
                                                                        <a href={`/contacts/${item.contact_id._id}`}>{item.contact_id.firstname} {item.contact_id.lastname}</a>
                                                                    </div>
                                                                    <div className={item.complete ? `text-sm leading-5 text-gray-400` : `text-sm leading-5 text-gray-500`}>
                                                                        {item.company_id.type === 'CLIENT' ?
                                                                            <a href={`/clients/${item.company_id._id}`}>{item.company_id.name}</a>
                                                                            :
                                                                            <a href={`/contractors/${item.company_id._id}`}>{item.company_id.name}</a>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                : item.quote_id ?
                                                                    <div className="ml-4">
                                                                        <div className={item.complete ? `text-sm leading-5 font-medium text-gray-400` : `text-sm leading-5 font-medium text-gray-900`}>
                                                                            <a href={`/quotes/${item.quote_id._id}`}>#HMG{item.quote_id.quoteNumber}</a>
                                                                        </div>
                                                                        <div className={item.complete ? `text-sm leading-5 font-medium text-gray-400` : `text-sm leading-5 font-medium text-gray-900`}>
                                                                            <em className="font-thin">(Assigned: {item.assigned_id.firstname} {item.assigned_id.lastname})</em>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="ml-4">
                                                                        <div className={item.complete ? `text-sm leading-5 font-medium text-gray-400` : `text-sm leading-5 font-medium text-gray-900`}>
                                                                            {item.company_id.type === 'CLIENT' ?
                                                                                <a href={`/clients/${item.company_id._id}`}>{item.company_id.name}</a>
                                                                                :
                                                                                <a href={`/contractors/${item.company_id._id}`}>{item.company_id.name}</a>
                                                                            }
                                                                        </div>
                                                                        <div className={item.complete ? `text-sm leading-5 font-medium text-gray-400` : `text-sm leading-5 font-medium text-gray-900`}>
                                                                            <em className="font-thin">(Assigned: {item.assigned_id.firstname} {item.assigned_id.lastname})</em>
                                                                        </div>
                                                                    </div>
                                                        }

                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <div className={item.complete ? `text-sm leading-5 text-gray-400` : `text-sm leading-5 text-gray-900`}>
                                                            {item.message}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {item.complete ?
                                                            <div>
                                                                <div className="text-sm leading-5 font-medium text-gray-400">
                                                                    Complete
                                            </div>
                                                                <div className="text-sm leading-5 text-gray-400">
                                                                    {moment(item.completed).format("ddd D MMM YY - HH:mm")}
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="text-sm leading-5 text-gray-900">
                                                                {item.due ?
                                                                    <span>
                                                                        {moment(item.due) < moment() ?
                                                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 capitalize">{moment(item.due).format("ddd DD MMM YY")}</span>
                                                                            : moment(item.due) < moment().add(5, 'days') ?
                                                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 capitalize">{moment(item.due).format("ddd DD MMM YY")}</span>
                                                                                :
                                                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 capitalize">{moment(item.due).format("ddd DD MMM YY")}</span>
                                                                        }
                                                                    </span>
                                                                    :
                                                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 capitalize">n/a</span>
                                                                }
                                                                <div style={{ float: 'right' }}>
                                                                    <svg onClick={() => { if (window.confirm('Are you sure you want to complete this task?')) this.completeTask(item._id) }} className="flex-shrink-0 h-5 w-5 text-cool-gray-400 group-hover:text-cool-gray-500 transition ease-in-out duration-150 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>


                                    {(this.props.company || this.props.contact || this.props.quote) &&
                                        <div>

                                            <hr />
                                            <div>
                                                <dl>
                                                    <div className="bg-gray-50 px-4 py-5 grid grid-cols-5 gap-4 sm:px-6">

                                                        <div>
                                                            <SelectBox
                                                                name="assigned_id"
                                                                label="Assigned to"
                                                                placeholder="Select..."
                                                                value={this.state.assigned_id}
                                                                maxLength={45}
                                                                asterix={true}
                                                                options={this.state.users}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <TextInput
                                                                name="due"
                                                                type="date"
                                                                label="Due"
                                                                value={this.state.due}
                                                                maxLength={85}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>
                                                        <div className="col-span-3">
                                                            <TextInput
                                                                name="message"
                                                                type="text"
                                                                label="Task"
                                                                value={this.state.message}
                                                                maxLength={2000}
                                                                asterix={true}
                                                                onChange={this.onChange.bind(this)}
                                                            />
                                                        </div>

                                                    </div>
                                                </dl>
                                            </div>

                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                                <SubmitButton
                                                    text="Add task"
                                                    onClick={this.addTask}
                                                />
                                            </div>

                                        </div>
                                    }

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )
    };

}

Tasks.propTypes = {
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
        userName: state.account.userName,
    }
};

export default connect(mapStateToProps)(Tasks);