import { takeEvery, select, call, put } from 'redux-saga/effects';
import { SET_SETTINGS } from '../actions/settings';

import APIUtils from '../api/APIUtils';
import moment from 'moment';

function getHeaders() {
    return new Promise(resolve => {
        APIUtils.getHeaders((data) => {
            resolve(data)
        });
    });
}

function* updateQuote(action) {
     
}

export default function* rootSaga() {
    yield takeEvery(SET_SETTINGS, updateQuote);
}


