import apiRequest from '.';
import APIUtils from './APIUtils';

class PricingAPI {

  getProducts(id, cb) {
    let url = `products`;
    if (id) {
      url += `?item=${id}`;
    }
    apiRequest({ endpoint: url }, cb);
  }

  getGroups(cb) {
    let url = `products/groups/all`;
    apiRequest({ endpoint: url }, cb);
  }

  getGroup(id, cb) {
    let url = `products/group/${id}`;
    apiRequest({ endpoint: url }, cb);
  }

  addGroup(payload, cb) {
    apiRequest({ endpoint: `products/group`, method: 'POST', payload }, cb);
  }

  updGroup(id, payload, cb) {
    apiRequest({ endpoint: `products/group/${id}`, method: 'PUT', payload }, cb);
  }

  delGroup(id, cb) {
    apiRequest({ endpoint: `products/group/${id}`, method: 'DELETE' }, cb);
  }

  getOption(id, cb) {
    let url = `products/${id}`;
    apiRequest({ endpoint: url }, cb);
  }

  addOption(payload, cb) {
    apiRequest({ endpoint: `products`, method: 'POST', payload }, cb);
  }

  updOption(id, payload, cb) {
    apiRequest({ endpoint: `products/${id}`, method: 'PUT', payload }, cb);
  }

  delOption(id, cb) {
    apiRequest({ endpoint: `products/${id}`, method: 'DELETE' }, cb);
  }

  getVatRates(cb) {
    let url = `vat`;
    apiRequest({ endpoint: url }, cb);
  }


}

export default new PricingAPI();