import cart from './cart';
import auth from  './auth';
import settings from './settings';
import account from './account';

import {combineReducers} from 'redux';

const rootReducer = combineReducers({
    cart,
    auth,
    settings,
    account,
});

export default rootReducer;