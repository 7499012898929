import React, { Component } from 'react';
import sdLib from '../libs/sdLib';
import {Link} from 'react-router-dom';
import auth from '../libs/auth';

function LoggedInNav(props) {

    let sd = new sdLib();
    let basket = props.data;
    let net = sd.sum(basket, "net").toFixed(2);
    let vat = (sd.sum(basket, "net") * 0.2).toFixed(2);
    let total = parseFloat((parseFloat(net) + parseFloat(vat))).toFixed(2);
    let items = 0;
    if (basket) {
        items = basket.length;
    }

    return (
        <div className="off-canvas position-right" id="offCanvas" data-off-canvas>
            <button className="close-button" aria-label="Close menu" type="button" data-close>
                <span aria-hidden="true">&times;</span>
            </button>
            <ul className="vertical menu">
                <li><Link to="/">Find Space</Link></li>
                <li><Link to="/diary">Your Bookings</Link></li>
                <li><Link to="/profile">My Profile</Link></li>
                <li><Link to="/profile">Add Payment</Link></li>
                <li><Link to="/help">Help</Link></li>
                <li><Link to="/logout">Logout</Link></li>
            </ul>
        </div>
    )
}

function LoggedOutNav(props) {
    return (
        <div></div>
    )
}

export default class MobileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            basket: []
        };
    }

    render() {
        return (
            auth.auth.isAuthenticated === true
            ? <LoggedInNav data={this.state.basket} {...this.props} />
            : <LoggedOutNav {...this.props} />
        )
    }

}



