import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Title } from '../../components/Title';
import { TodaysMetrics } from '../../components/MetricSidebar';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import CompanyAPI from '../../api/CompanyAPI';
import ContactAPI from '../../api/ContactAPI';
import * as QueryString from "query-string";

class ContactEditPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            company_id: '',
            firstname: '',
            lastname: '',
            title: '',
            email: '',
            line1: '',
            line2: '',
            town: '',
            county: '',
            postcode: '',
            telephone: '',
            mobile: '',
            errFirstname: '',
            errLastname: '',
        }

        this.save = this.save.bind(this);

    }

    componentDidMount() {
        if (this.state.id !== 'new') {
            this.loadContact();
        } else {
            const params = QueryString.parse(this.props.location.search);
            if (params.company) {
                this.setState({
                    company_id: params.company
                });
            }
        }
    }

    loadContact() {
        ContactAPI.getContact(this.state.id, (data) => {
            this.setState({
                company_id: data.company_id,
                firstname: data.firstname,
                lastname: data.lastname,
                title: data.title,
                email: data.email,
                line1: data.line1,
                line2: data.line2,
                town: data.town,
                county: data.county,
                postcode: data.postcode,
                telephone: data.telephone,
                mobile: data.mobile,
            });
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    save() {

        var _ok = true;

        if (this.state.firstname === "") {
            _ok = false;
            this.setState({
                errFirstname: 'Please enter the first name'
            });
        }
        if (this.state.lastname === "") {
            _ok = false;
            this.setState({
                errLastname: 'Please enter the last name'
            });
        }

        if (_ok) {
                
            let payload = {
                company_id: this.state.company_id,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                title: this.state.title,
                email: this.state.email,
                line1: this.state.line1,
                line2: this.state.line2,
                town: this.state.town,
                county: this.state.county,
                postcode: this.state.postcode,
                telephone: this.state.telephone,
                mobile: this.state.mobile,
            }

            console.log(payload);

            if (this.state.id === 'new') {
                ContactAPI.addContact(payload, (data) => {
                    console.log("addContact()", data);
                    this.props.history.push(`/contacts/${data._id}`);
                });
            } else {
                ContactAPI.updContact(this.state.id, payload, (data) => {
                    console.log("updContact()", data);
                    this.props.history.push(`/contacts/${this.state.id}`);
                });
            }

        }
        
    }

    render() {

        return (
            <div className="h-screen flex overflow-hidden bg-cool-gray-100">

            <Menu {...this.props} />
            <MobileMenu {...this.props} />

            <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">
              
              <Header {...this.props} />

              <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

                {/* begin page header */}
                <div className="bg-white shadow">
                  <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                    <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                      <div className="flex-1 min-w-0">
                        <div className="flex items-center">
                          <div>
                            <div className="flex items-center">
                              <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                                Contact
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
                {/* end page header */}
          
                <div className="mt-8">
                {/* begin content block */}
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    
                    <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                            <p className="mt-1 text-sm leading-5 text-gray-600">
                            The customers personal details.
                            </p>
                        </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="firstname"
                                            type="text"
                                            label="First name"
                                            value={this.state.firstname}
                                            maxLength={85}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errFirstname}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="lastname"
                                            type="text"
                                            label="Last name"
                                            value={this.state.lastname}
                                            maxLength={85}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errLastname}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="title"
                                            type="text"
                                            label="Job title"
                                            value={this.state.title}
                                            maxLength={85}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="email"
                                            type="text"
                                            label="Email address"
                                            value={this.state.email}
                                            maxLength={75}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="telephone"
                                            type="tel"
                                            label="Telephone number"
                                            value={this.state.telephone}
                                            maxLength={75}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="mobile"
                                            type="tel"
                                            label="Mobile number"
                                            value={this.state.mobile}
                                            maxLength={75}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <SubmitButton
                                    text="Save"
                                    onClick={this.save}
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className="hidden sm:block">
                        <div className="py-5">
                            <div className="border-t border-gray-200"></div>
                        </div>
                    </div>

                    <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Address Information</h3>
                            <p className="mt-1 text-sm leading-5 text-gray-600">
                            The customers postal address.
                            </p>
                        </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="line1"
                                            type="text"
                                            label="Address (line 1)"
                                            value={this.state.line1}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="line2"
                                            type="text"
                                            label="Address (line 2)"
                                            value={this.state.line2}
                                            maxLength={45}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="town"
                                            type="text"
                                            label="Town"
                                            value={this.state.town}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="county"
                                            type="text"
                                            label="County"
                                            value={this.state.county}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="postcode"
                                            type="text"
                                            label="Post code"
                                            value={this.state.postcode}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                
                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <SubmitButton
                                    text="Save"
                                    onClick={this.save}
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>
                {/* end content block */}
                </div>

              </main>
            </div>
          </div>

        )

    }

}

ContactEditPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(ContactEditPage);