import auth from './auth';

const baseUrl = 'https://streetdotsapi.azurewebsites.net/';

export default class sdLib {

  sum(items, prop) {
    if (items !== null) {
      return items.reduce( function(a, b){
          return a + b[prop];
      }, 0);
    } else {
      return 0;
    }
  }

  getHeaders() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('auth_token', '9341AC58-36F8-4654-A532-061436EC1D1C');
    //headers.append('auth_token', auth.auth.userKey);
    return headers;
  }

  authenticate(username, password, cb) {
    const headers = this.getHeaders();
    let url = `${baseUrl}user/auth`;
    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({username: username, password: password})
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }
  fetchTraders(keyword, cb) {
    const headers = this.getHeaders();
    let url = `${baseUrl}consumer/traders?keyword=${keyword}`;
    console.log(url);
    fetch(url, {
      method: 'GET',
      headers
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

  fetchTrader(id, cb) {
    const headers = this.getHeaders();
    let url = `${baseUrl}trader/details?id=${id}`;
    console.log(url);
    fetch(url, {
      method: 'GET',
      headers
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

  invoice(options = {}, cb) {
    const headers = this.getHeaders();
    let user = auth.auth.userKey;
    let url = '';
    if (options.id !== undefined) {
      url = `${baseUrl}user/invoice?userKey=${options.userKey}&id=${options.id}`;
    } else {
      url = `${baseUrl}user/invoices?userKey=${options.userKey}`;
    }
    console.log(url);
    return fetch(url, {
      method: 'GET',
      headers
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

  addInvoice(data = {}, cb) {
    const headers = this.getHeaders();
    let url = `${baseUrl}user/invoice`;
    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

  sendInvoice(data = {}, cb) {
    const headers = this.getHeaders();
    let url = `${baseUrl}user/sendinvoice`;
    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

  sendMessage(data = {}, cb) {
    //const headers = this.getHeaders();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('auth_token', '9341AC58-36F8-4654-A532-061436EC1D1C');
    let url = `${baseUrl}trader/message`;
    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

  fetchMessages(userKey, cb) {
		const headers = this.getHeaders();
    let url = `${baseUrl}trader/messages?id=${userKey}`;
    console.log(url);
    fetch(url, {
      method: 'GET',
      headers
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

  fetchSupport(cb) {
		const headers = this.getHeaders();
    let url = `${baseUrl}user/support`;
    console.log(url);
    fetch(url, {
      method: 'GET',
      headers
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

  traderDocuments(options = {}, cb) {
    const headers = this.getHeaders();
    let url = '';
    if (options.id !== undefined) {
      url = `${baseUrl}trader/documents?traderKey=${options.id}&expired=${options.expired}&group=${options.group}`;
    }
    fetch(url, {
      method: 'GET',
      headers
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

  getStandards(options = {}, cb) {
    const headers = this.getHeaders();
    let url = '';
    if (options.id !== undefined) {
      url = `${baseUrl}trader/standards?traderKey=${options.id}`;
    }
    fetch(url, {
      method: 'GET',
      headers
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

  getLocationAnalysis(options = {}, cb) {
    const headers = this.getHeaders();
    let url = `${baseUrl}location/geoanalysis?location=${options.location}&distance=${options.distance}`;
    //url += '&unitType=' + unitType;
	  //url += '&classification=' + classification;
		//url += '&tier=' + tier;
		//url += '&status=' + status;
		//url += '&emailAddress=' + emailAddress;
    fetch(url, {
      method: 'GET',
      headers
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

  getTicketLabels(cb) {
		const headers = this.getHeaders();
    let url = `${baseUrl}helpdesk/labels`;
    console.log(url);
    fetch(url, {
      method: 'GET',
      headers
    }).then((response) => {
      return response.json();
    })
    .then((result) => {
      cb(result);
    })
    .catch((err) => {
      cb(err);
    });
  }

}