import React from 'react';

const MetricBlock = ({ children }) => (
    <div className="row metric-block">
        <div className="col-12">
            {children}
        </div>
    </div>
);

export default MetricBlock;