import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Title } from '../../components/Title';
import { TodaysMetrics } from '../../components/MetricSidebar';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import { SelectBox } from '../../components/SelectBox';
import UserAPI from '../../api/UserAPI';
import { Tasks } from '../../components/Tasks';

class UserEditPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            type: 'USER'
        }

        this.save = this.save.bind(this);

    }

    componentDidMount() {
        if (this.state.id !== 'new') {
            this.loadUser();
        }
    }

    loadUser() {
        UserAPI.getUser(this.state.id, (data) => {
            this.setState({
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
                password: data.password,
                type: data.type
            });
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    save() {

        let payload = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            password: this.state.password,
            type: this.state.type,
        }

        if (this.state.id === 'new') {
            UserAPI.addUser(payload, (data) => {
                console.log("addUser()", data);
                this.props.history.push(`/users/${data._id}`);
            });
        } else {
            UserAPI.updUser(this.state.id, payload, (data) => {
                console.log("updUser()", data);
                this.props.history.push(`/users/${this.state.id}`);
            });
        }
    }

    render() {

        return (
            <div className="h-screen flex overflow-hidden bg-cool-gray-100">

            <Menu {...this.props} />
            <MobileMenu {...this.props} />

            <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">
              
              <Header {...this.props} />

              <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

                {/* begin page header */}
                <div className="bg-white shadow">
                  <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                    <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                      <div className="flex-1 min-w-0">
                        <div className="flex items-center">
                          <div>
                            <div className="flex items-center">
                              <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                                Customers
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
                {/* end page header */}
          
                <div className="mt-8">
                {/* begin content block */}
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    
                    <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                            <p className="mt-1 text-sm leading-5 text-gray-600">
                            Use a permanent address where you can receive mail.
                            </p>
                        </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="firstname"
                                            type="text"
                                            label="First name"
                                            value={this.state.firstname}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="lastname"
                                            type="text"
                                            label="Last name"
                                            value={this.state.lastname}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="email"
                                            type="text"
                                            label="Email address"
                                            value={this.state.email}
                                            maxLength={75}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="password"
                                            type="password"
                                            label="Password"
                                            value={this.state.password}
                                            maxLength={75}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <SelectBox 
                                            name="type"
                                            label="User Type"
                                            placeholder="Select..."
                                            value={this.state.type}
                                            maxLength={45}
                                            asterix={true}
                                            options={[
                                                {value: 'USER', label: 'USER'},
                                                {value: 'ADMIN', label: 'ADMIN'}
                                            ]}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <SubmitButton
                                    text="Save"
                                    onClick={this.save}
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>
                {/* end content block */}
                </div>

              </main>
            </div>
          </div>

        )

    }

}

UserEditPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(UserEditPage);