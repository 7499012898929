import React, { Component } from 'react';
import { MetricBlock, MetricTitle, SimpleValue, MetricNumber } from '../../components/Metric';
import { ColumnLineChart } from '../../components/Chart';

class TodaysMetrics extends React.Component {

    constructor() {
        super();
        this.state = {
            revenue: 0,
            bookings: 0,
            revenuecompare: 0,
            bookingscompare: 0,
            hourly: [],
            registrations: 0,
            approvals: 0,
            registrationscompare: 0,
            approvalscompare: 0,
        };
    }

    componentDidMount() {
        //this.loadMetrics();
    }

    render() {
        return (
            <div>
                <MetricBlock>
                    <MetricTitle title="Total Sales" label="Today" />
                    <MetricNumber value={`£${this.state.revenue.toFixed(2)}`} label={this.state.bookings === 1 ? '1 booking' : this.state.bookings + ' bookings'} />
                    <ColumnLineChart data={this.state.hourly} />
                </MetricBlock>

                <MetricBlock>
                    <MetricTitle title="Today's Activity" label="Today" compare="Last Week" />
                    <SimpleValue title="New Bookings" value={this.state.bookings} compare={this.state.bookingscompare} />
                    <SimpleValue title="New Trader Registrations" value={this.state.registrations} compare={this.state.registrationscompare} />
                    <SimpleValue title="New Approved Traders" value={this.state.approvals} compare={this.state.approvalscompare} />
                </MetricBlock>
            </div>
        )
    }

};

export default TodaysMetrics;