import React, { Component } from 'react';
import {Route, Redirect, Link} from 'react-router-dom';
import sdLib from './sdLib';

const auth = {
    get isAuthenticated() {
        return !!localStorage.getItem('token');
    },
    get userKey() {
        return localStorage.getItem('token');
    },
    get profile() {
        return JSON.parse(localStorage.getItem('profile'));
    },
    get traderKey() {
        if (this.isAuthenticated) {
            let json = this.profile;
            return json.traderKey;
        } else {
            return null;
        }
    },
    authenticate(username, password, fn) {
        let sd = new sdLib();
        sd.authenticate(username, password, (obj) => {
            console.log(obj);
            if (obj.ok) {
                localStorage.setItem('profile', JSON.stringify(obj));
                localStorage.setItem('token', obj.userKey);
                fn(obj);
            } else {
                fn(obj);
            }
        });
    },
    signout(fn) {
        localStorage.removeItem('profile');
        localStorage.removeItem('token');
        return <Redirect to="/login" />;
        fn();
    },
    verifyAuth() {
        if (!auth.isAuthenticated) {
            return <Redirect to="/login" />;
        }
    }
}

export default {auth};