import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import { Notes } from '../../components/Notes';
import QuoteAPI from '../../api/QuoteAPI';
import { Tasks } from '../../components/Tasks';
import { SubmitButton, SwitchButton } from '../../components/Button';
import PricingAPI from '../../api/PricingAPI';
import ProductAPI from '../../api/ProductAPI';
import { SelectBox } from '../../components/SelectBox';
import { TextInput } from '../../components/TextInput';

class QuotePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      name: '',
      email: '',
      line1: '',
      line2: '',
      town: '',
      county: '',
      postcode: '',
      quoteNumber: '',
      company: '',
      company_id: '',
      created: '',
      modified: '',
      status: '',
      items: [],
      discount: 0,
      price: 0,
      reference: '',
      manufacturer_ref: '',
      expected_date: '',
      reason: '',
      contractor_id: '',
      contractor_name: '',
      modal: false,
      groups: [],
      products: [],
      vat: [],
      keyword: '',
      category: '',
      lineId: '',
      basket: [],
      line_reference: '',
      quantity: 1,
      width: 0,
      height: 0,
      frame_width: 0,
      frame_height: 0,
      view: 'basket',
      item_price: 0,
      item_description: '',
      manual: false,
      vat_id: '',
      product_id: '',
      product_name: '',
      product_pricing: [],
      product_width: '',
      product_height: '',
      product_price: '',
      productOptions: [
        {
          value: 0,
          label: 'Manual Line Entry'
        }, {
          value: 1,
          label: 'General Purpose Single Door'
        }, {
          value: 2,
          label: 'General Purpose Double Door'
        }, {
          value: 3,
          label: 'Fire Exit Single'
        }, {
          value: 4,
          label: 'Fire Exit Double'
        }, {
          value: 5,
          label: 'Fire Rated Single Door'
        }, {
          value: 6,
          label: 'Fire Rated Double Door'
        }, {
          value: 7,
          label: 'Fire Exit Single (Fire Rated)'
        }, {
          value: 8,
          label: 'Fire Exit Double (Fire Rated)'
        }
      ],
      product_group: 0,
      color: '',
      colors: [],
      selectedOnly: false,
    }

    this.addItem = this.addItem.bind(this);
    this.copyItem = this.copyItem.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.addNewItem = this.addNewItem.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.addLineItem = this.addLineItem.bind(this);
    this.removeLineItem = this.removeLineItem.bind(this);

  }

  toggleSelectedOnly(name, value) {
    this.setState({
      selectedOnly: !this.state.selectedOnly
    });
  }

  componentDidMount() {
    this.loadGroups();
    this.loadQuote();
    this.loadQuoteItems();
    this.loadVatRates();
    this.loadColors();
  }

  loadColors() {
    ProductAPI.getColors((data) => {
      var _arr = [];
      for (var i = 0; i < data.length; i++) {
        _arr.push({
          value: data[i]._id,
          label: data[i].name + ' (' + data[i].ral + ')',
          hex: data[i].hex,
        });
      }
      this.setState({
        colors: _arr
      });
    })
  }

  loadVatRates() {
    PricingAPI.getVatRates((data) => {
      var _arr = [];
      for (var i = 0; i < data.length; i++) {
        _arr.push({
          value: data[i]._id,
          label: data[i].name,
        });
      }
      this.setState({
        vat: _arr
      });
    })
  }

  onSearchInput(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      keyword: value,
      category: '',
      view: 'products'
    });
  }

  loadGroups() {
    PricingAPI.getGroups((data) => {
      console.log("getGroups", data);
      this.setState({
        groups: data
      });
    });
  }

  loadQuote() {
    QuoteAPI.getQuote(this.state.id, (data) => {
      console.log("quote:", data);
      this.setState({
        name: data.name,
        email: data.email,
        line1: data.line1,
        line2: data.line2,
        town: data.town,
        county: data.county,
        postcode: data.postcode,
        quoteNumber: data.quoteNumber,
        company: data.company_id.name,
        company_id: data.company_id._id,
        author: data.author_id.firstname + ' ' + data.author_id.lastname,
        created: data.created,
        modified: data.modified,
        status: data.status,
        discount: data.discount,
        price: data.price,
        reference: data.reference,
        manufacturer_ref: data.manufacturer_ref,
        expected_date: data.expected_date ? moment(data.expected_date).format("ddd DD MMM YYYY") : 'Unknown',
        reason: data.reason,
        contractor_id: data.contractor_id ? data.contractor_id._id : '',
        contractor_name: data.contractor_id ? data.contractor_id.name : '',
        color: data.color ? data.color._id : '',
      });
    })
  }

  copyItem(id) {
    QuoteAPI.copyItem(id, (data) => {
      this.loadQuote();
      this.loadQuoteItems();
    });
  }

  loadQuoteItems() {
    QuoteAPI.getQuoteItems(this.state.id, (data) => {
      console.log("loadQuoteItems", data);
      this.setState({
        items: data
      });
    });
  }

  addItem() {
    this.props.history.push(`/quotes/${this.state.id}/new`);
  }

  downloadPdf() {
    QuoteAPI.getQuotePDF(this.state.id, (data) => {
      window.open(`https://hmg.s3.eu-west-2.amazonaws.com/${data.filename}`);
    });
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
    }, () => {
      if (name === 'width' && parseFloat(value) > 10) {
        this.setState({
          frame_width: parseFloat(value) - 10
        });
      }
      if (name === 'height' && parseFloat(value) > 10) {
        this.setState({
          frame_height: parseFloat(value) - 10
        });
      }
      if (name === 'product_group') {
        this.loadProducts();
      }
    });
  }

  toggleModal() {
    this.setState({
      keyword: '',
      category: '',
      view: 'basket',
      modal: !this.state.modal,
      lineId: this.state.modal ? '' : this.state.lineId,
      basket: this.state.modal ? [] : this.state.basket,
      line_reference: this.state.modal ? '' : this.state.line_reference,
      quantity: this.state.modal ? 1 : this.state.quantity,
      width: this.state.modal ? 0 : this.state.width,
      height: this.state.modal ? 0 : this.state.height,
      frame_width: this.state.modal ? 0 : this.state.frame_width,
      frame_height: this.state.modal ? 0 : this.state.frame_height,
    });
  }

  setCategory(id) {
    console.log(`setCategory(${id})`);
    this.setState({
      category: id,
      view: 'products'
    })
  }

  editItem(id, modal) {

    QuoteAPI.getQuoteItem(id, (data) => {

      console.log("getQuoteItem()", data);

      this.setState({
        lineId: id,
        basket: data.item_options,
        line_reference: data.reference,
        product_group: data.product_group,
        quantity: data.quantity,
        width: data.width,
        height: data.height,
        frame_width: data.frame_width,
        frame_height: data.frame_height,
        item_price: data.price,
        item_description: data.item_description,
        manual: data.manual,
        vat_id: data.vat_id,
        modal: modal,
        color: data.color,
      }, () => {
        this.loadProducts();
      });

    });

  }

  deleteItem(id) {
    QuoteAPI.delQuoteItem(id, (data) => {
      this.loadQuote();
      this.loadQuoteItems();
    });
  }

  setView(view) {
    this.setState({
      view: view
    });
  }

  addLineItem(id) {
    this.setState({
      basket: this.state.basket.concat({ product_id: { _id: id } }),
      view: 'basket',
    }, () => {
      this.saveItem(false);
    });
  }


  removeLineItem(_index) {
    this.setState({
      basket: this.state.basket.filter((item, index) => index !== _index),
      view: 'basket',
    }, () => {
      this.saveItem(false);
    });
  }

  saveItem(close) {

    var _options = [];
    for (var i = 0; i < this.state.products.length; i++) {
      if (parseInt(this.state.products[i].quantity) > 0) {
        _options.push({
          product_id: this.state.products[i].id,
          quantity: this.state.products[i].quantity,
        });
      }
    }

    let payload = {
      quote_id: this.state.id,
      product_group: this.state.product_group,
      reference: this.state.line_reference,
      quantity: this.state.quantity,
      width: this.state.width,
      height: this.state.height,
      frame_width: this.state.frame_width,
      frame_height: this.state.frame_height,
      item_options: _options,
      manual: parseInt(this.state.product_group) === 0,
      price: this.state.item_price,
      item_description: this.state.item_description,
      vat_id: this.state.vat_id,
      color: this.state.color,
    }

    console.log(payload);

    QuoteAPI.updQuoteItem(this.state.lineId, payload, (data) => {
      console.log("updQuoteItem()", data);
      this.loadQuote();
      this.loadQuoteItems();
      this.editItem(this.state.lineId, !close);
      if (close) {
        this.toggleModal();
      }
    });

  }

  loadProducts() {
    ProductAPI.getProducts(this.state.lineId, this.state.product_group, (data) => {
      console.log(data);
      this.setState({
        products: data
      });
    });
  }

  addNewItem() {

    let payload = {
      quote_id: this.state.id,
      product_group: 0,
      reference: '',
      quantity: 1,
      width: 0,
      height: 0,
      frame_width: 0,
      frame_height: 0,
      item_options: [],
      manual: false,
      item_price: 0,
      item_description: '',
      vat_id: '60e6d15bd48e7f000465cbc2',
      color: '6284e999354a896b1a45b668',
    }

    QuoteAPI.addQuoteItem(payload, (data) => {
      this.editItem(data._id, true);
    });

  }

  saveConfiguration(e) {

  }

  setQty(e, id) {
    var _results = [];
    var _qty = e.target.value;
    var items = this.state.products;
    for (let i = 0; i < items.length; i++) {
      var item = items[i];
      if (item.id === id) {
        item.quantity = _qty;
        _results.push(item);
      } else {
        _results.push(item);
      }
    }
    this.setState({
      products: _results
    });
  }

  render() {

    let unit_total = 0;
    let discount_total = 0;
    let vat_total = 0;
    let total_total = 0;

    return (
      <div className="h-screen flex overflow-hidden bg-cool-gray-100">

        <Menu {...this.props} />
        <MobileMenu {...this.props} />

        <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">

          <Header {...this.props} />

          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

            {/* begin page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                            {this.state.id === 'new' ? `New Quote` : `Quote #HMG${this.state.quoteNumber}`}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <span className="shadow-sm rounded-md">
                      <a onClick={() => this.downloadPdf()} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium cursor-pointer rounded-md text-white bg-teal-600 focus:outline-none focus:shadow-outline-teal transition duration-150 ease-in-out">
                        Download PDF
                      </a>
                    </span>
                    <span className="shadow-sm rounded-md">
                      <a href={`/quotes/edit/${this.state.id}`} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline-teal focus:border-teal-700 active:bg-teal-700 transition duration-150 ease-in-out">
                        Edit
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* end page header */}

            <div className="mt-8">
              {/* begin content block */}

              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">


                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="bg-white rounded-md shadow px-6 flex space-x-4 mb-8">
                    <li className="flex">
                      <div className="flex items-center">
                        <a href="/" className="text-gray-400 hover:text-gray-500">
                          <svg className="flex-shrink-0 h-5 w-5 transition duration-150 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                          </svg>
                        </a>
                        <span className="sr-only">Home</span>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center space-x-4">
                        <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <a href="/quotes" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Quotes</a>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center space-x-4">
                        <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <a href={`/quotes/${this.state.id}`} aria-current="page" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">#HMG{this.state.quoteNumber}</a>
                      </div>
                    </li>
                  </ol>
                </nav>


                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Quote Information
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                      Quote details and project information.
                    </p>
                  </div>
                  <div>
                    <dl>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Quote reference
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          #HMG{this.state.quoteNumber}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Expected order date
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.expected_date}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Customer reference
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.reference}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Manufacturer reference
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.manufacturer_ref}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Quote status
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800 capitalize">
                            {this.state.status === 'enquiry' ? 'Enquiry' :
                              this.state.status === 'rfq_sent' ? 'RFQ Sent to Manufacturer' :
                                this.state.status === 'quote_sent' ? 'Quote Sent' :
                                  this.state.status === 'quote_revised' ? 'Quote Revised' :
                                    this.state.status === 'invoice_raised' ? 'Invoice Raised' :
                                      this.state.status === 'payment_received' ? 'Deposit / Full Payment Received' :
                                        this.state.status === 'visit_arranged' ? 'Site Visit Arranged' :
                                          this.state.status === 'visit_revision' ? 'Site Visit Revision' :
                                            this.state.status === 'delivery_date' ? 'Delivery Date Received' :
                                              this.state.status === 'install_arranged' ? 'Installation Date Arranged' :
                                                this.state.status === 'install_complete' ? 'Installation Complete' :
                                                  this.state.status === 'install_payment' ? 'Installation Payment Received' :
                                                    this.state.status === 'follow_up' ? 'Courtesy Follow Up' :
                                                      this.state.status === 'rejected' ? 'Rejected' :
                                                        'Unknown'}
                          </span>
                        </dd>
                      </div>
                      {this.state.status === 'rejected' &&
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            Rejection reason
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {this.state.reason}
                          </dd>
                        </div>
                      }
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Client name
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          <a href={`/clients/${this.state.company_id}`}>
                            <span>{this.state.company}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 external-link" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                            </svg>
                          </a>
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Project name
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.name}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Project address
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.line1 ? <div>{this.state.line1}</div> : ''}
                          {this.state.line2 ? <div>{this.state.line2}</div> : ''}
                          {this.state.town ? <div>{this.state.town}</div> : ''}
                          {this.state.county ? <div>{this.state.county}</div> : ''}
                          {this.state.postcode ? <div>{this.state.postcode}</div> : ''}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Quote prepared by
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.author}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Quote created
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {moment(this.state.created).format("ddd D MMM YY - HH:mm")}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Quote last updated
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {moment(this.state.modified).format("ddd D MMM YY - HH:mm")}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Discount rate
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.discount.toFixed(2)}%
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Total Cost
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          &pound;{(parseFloat(this.state.price) + (((parseFloat(this.state.price) / 100) * parseFloat(this.state.discount) * -1)) + ((parseFloat(this.state.price) - ((parseFloat(this.state.price) / 100) * parseFloat(this.state.discount))) * 0.2)).toFixed(2)} (including discount and VAT)
                        </dd>
                      </div>
                      {this.state.contractor_id &&
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            Contractor Assigned
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            <a href={`/contractors/${this.state.contractor_id}`}>
                              <span>{this.state.contractor_name}</span>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 external-link" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                              </svg>
                            </a>
                          </dd>
                        </div>
                      }
                      {/*
      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm leading-5 font-medium text-gray-500">
          Attachments
        </dt>
        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
          <ul className="border border-gray-200 rounded-md">
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
              <div className="w-0 flex-1 flex items-center">
                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                </svg>
                <span className="ml-2 flex-1 w-0 truncate">
                  resume_back_end_developer.pdf
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out">
                  Download
                </a>
              </div>
            </li>
            <li className="border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
              <div className="w-0 flex-1 flex items-center">
                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                </svg>
                <span className="ml-2 flex-1 w-0 truncate">
                  coverletter_back_end_developer.pdf
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out">
                  Download
                </a>
              </div>
            </li>
          </ul>
        </dd>
      </div>
      */}
                    </dl>
                  </div>
                </div>



                <div className="hidden sm:block">
                  <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                  </div>
                </div>


                <div>
                  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Line Items
                      </h3>
                    </div>

                    <div className="flex flex-col">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

                            <table className="min-w-full divide-y divide-gray-200">
                              <thead>
                                <tr>
                                  {/*
                                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="10%">
                                    Reference
                                  </th>
    */}
                                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="40%">
                                    Item
                                  </th>
                                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="10%">
                                    Unit
                                  </th>
                                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="10%">
                                    Discount
                                  </th>
                                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="20%">
                                    VAT
                                  </th>
                                  <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="10%">
                                    Total
                                  </th>
                                  <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="10%">&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {this.state.items.length === 0 &&
                                  <tr>
                                    <td colSpan="6" className="px-6 py-4 whitespace-no-wrap">
                                      <div className="text-sm leading-5 text-cool-gray-500 text-center">
                                        No line items added.
                                      </div>
                                    </td>
                                  </tr>
                                }
                                {this.state.items.map((item, index) => {
                                  const unit_price = item.price;
                                  const total_price = item.price * item.quantity;
                                  const discount = (parseFloat(total_price) / 100) * (parseFloat(this.state.discount) * -1);
                                  const vat = item.vat_id ? (total_price + discount) * item.vat_id.rate : 0;
                                  const total = total_price + discount + vat;

                                  unit_total = unit_total + total_price;
                                  discount_total = discount_total + discount;
                                  vat_total = vat_total + vat;
                                  total_total = total_total + total;

                                  return (
                                    <tr key={index}>
                                      {/*
                                      <td className="px-6 py-4 whitespace-no-wrap">
                                        <div className="text-sm leading-5 text-gray-900">
                                          <a href={`/quotes/${this.state.id}/${item._id}`}>{item.reference ? item.reference : 'n/a'}</a>
                                        </div>
                                      </td>
                                  */}
                                      <td className="px-6 py-4">
                                        {item.manual ?
                                          <div className="text-sm leading-5 text-gray-900">{item.quantity} x {item.item_description}</div>
                                          :
                                          <div className="text-sm leading-5 text-gray-900">
                                            {item.quantity} x {parseInt(item.product_group) === 1 ? 'General Purpose Single Door' : parseInt(item.product_group) === 2 ? 'General Purpose Double Door' : parseInt(item.product_group) === 3 ? 'Fire Exit Single' : parseInt(item.product_group) === 4 ? 'Fire Exit Double' : parseInt(item.product_group) === 5 ? 'Fire Rated Single Door' : parseInt(item.product_group) === 6 ? 'Fire Rated Double Door' : parseInt(item.product_group) === 7 ? 'Fire Exit Single (Fire Rated)' : parseInt(item.product_group) === 8 ? 'Fire Exit Double (Fire Rated)' : ''}
                                            <br /><span>{item.width}mm x {item.height}mm</span>
                                          </div>
                                        }
                                      </td>
                                      <td className="px-6 py-4">
                                        <div className="text-sm leading-5 text-gray-900">
                                          &pound;{parseFloat(unit_price).toFixed(2)}
                                        </div>
                                      </td>
                                      <td className="px-6 py-4">
                                        <div className="text-sm leading-5 text-gray-900">
                                          &pound;{parseFloat(discount).toFixed(2)}
                                        </div>
                                      </td>
                                      <td className="px-6 py-4">
                                        <div className="text-sm leading-5 text-gray-900">
                                          {item.vat_id ? item.vat_id.name : '-'}
                                        </div>
                                      </td>
                                      <td className="px-6 py-4">
                                        <div className="text-sm leading-5 text-gray-900 text-right">
                                          &pound;{parseFloat(total).toFixed(2)}
                                        </div>
                                      </td>
                                      <td className="px-6 py-4">
                                        <div className="text-sm leading-5 text-gray-900 text-right">
                                          <svg onClick={() => this.editItem(item._id, true)} alt="Edit" style={{ width: '16px' }} xmlns="http://www.w3.org/2000/svg" className="inline-block h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                          </svg>

                                          <svg onClick={() => { if (window.confirm('Are you sure you want to delete this line?')) this.deleteItem(item._id) }} alt="Delete" style={{ width: '16px' }} className="inline-block ml-4 w-6 h-6 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                          </svg>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                }
                                )}
                                {this.state.items.length > 0 &&
                                  <tr>
                                    <td className="px-6 py-4 bg-gray-50">
                                      <div className="text-sm leading-5 font-medium text-gray-500 text-right">
                                        TOTALS
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 bg-gray-50">
                                      <div className="text-sm leading-5 font-medium text-gray-500">
                                        &pound;{parseFloat(unit_total).toFixed(2)}
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 bg-gray-50">
                                      <div className="text-sm leading-5 font-medium text-gray-500">
                                        &pound;{parseFloat(discount_total).toFixed(2)}
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 bg-gray-50">
                                      <div className="text-sm leading-5 font-medium text-gray-500">
                                        &pound;{parseFloat(vat_total).toFixed(2)}
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 bg-gray-50">
                                      <div className="text-sm leading-5 font-medium text-gray-500 text-right">
                                        &pound;{parseFloat(total_total).toFixed(2)}
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 bg-gray-50">&nbsp;</td>
                                  </tr>
                                }
                              </tbody>
                            </table>

                            <hr />

                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                              <SubmitButton
                                text="Add New Line Item"
                                onClick={this.addNewItem}
                              />
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="hidden sm:block">
                  <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                  </div>
                </div>

                <Tasks quote={this.state.id} />

                <div className="hidden sm:block">
                  <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                  </div>
                </div>

                <Notes id={this.state.id} />





              </div>

              {/* end content block */}
            </div>


            {this.state.modal &&
              <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                  <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">

                    <div className="quote-form">

                      <div className="close">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={this.toggleModal}>
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>

                      <div className="grid grid-cols-4 gap-4 quote-header">
                        <div className="col-span-4">

                          <div className="relative w-full text-cool-gray-400 focus-within:text-cool-gray-600 pr-10">
                            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                              <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" clipRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path></svg>
                            </div>
                            <input type="search" id="search_field" autoComplete="off" className="block w-full h-full pl-8 pr-3 py-2 rounded-md text-cool-gray-900 placeholder-cool-gray-500 focus:outline-none focus:placeholder-cool-gray-400 sm:text-sm" placeholder="Search" value={this.state.keyword} onChange={this.onSearchInput.bind(this)} />
                          </div>

                        </div>
                      </div>

                      <div className="grid grid-cols-4 gap-4">

                        <div className="col-span-4">
                          <div className="quote-main">

                            <div className="mt-5 md:mt-0 md:col-span-2 px-4 py-5">

                              <div className="grid grid-cols-3 gap-6">
                                <div className="col-span-2 sm:col-span-1">
                                  <SelectBox
                                    name="product_group"
                                    label=""
                                    placeholder="Select..."
                                    value={this.state.product_group}
                                    maxLength={45}
                                    asterix={true}
                                    options={this.state.productOptions}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-5 md:mt-0 md:col-span-2 px-4 py-5">
                              <div className="grid grid-cols-3 gap-6">

                                <div className="col-span-2 sm:col-span-1">
                                  <TextInput
                                    name="quantity"
                                    label="Quantity"
                                    type="number"
                                    value={this.state.quantity}
                                    maxLength={45}
                                    asterix={true}
                                    onChange={this.onChange.bind(this)}
                                    error={this.state.errQuantity}
                                  />
                                </div>

                                <div className="col-span-2 sm:col-span-1">
                                  <SelectBox
                                    name="vat_id"
                                    label="VAT Rate"
                                    placeholder="Select..."
                                    value={this.state.vat_id}
                                    maxLength={45}
                                    asterix={true}
                                    options={this.state.vat}
                                    onChange={this.onChange.bind(this)}
                                    error={this.state.errVat}
                                  />
                                </div>

                                {(parseInt(this.state.product_group) === 0) &&
                                  <div className="col-span-2 sm:col-span-1">
                                    <TextInput
                                      name="item_price"
                                      label="Price (ex VAT)"
                                      type="number"
                                      value={this.state.item_price}
                                      maxLength={45}
                                      asterix={true}
                                      onChange={this.onChange.bind(this)}
                                      error={this.state.errPrice}
                                    />
                                  </div>
                                }
                                {(parseInt(this.state.product_group) === 0) &&
                                  <div className="col-span-2 sm:col-span-3">
                                    <TextInput
                                      name="item_description"
                                      label="Description"
                                      type="text"
                                      value={this.state.item_description}
                                      maxLength={45}
                                      asterix={true}
                                      onChange={this.onChange.bind(this)}
                                      error={this.state.errDescription}
                                    />
                                  </div>
                                }

                                {!(parseInt(this.state.product_group) === 0) &&
                                  <div className="col-span-2 sm:col-span-1">
                                    <TextInput
                                      name="width"
                                      label="Struc. Width (mm)"
                                      type="number"
                                      value={this.state.width}
                                      maxLength={45}
                                      asterix={true}
                                      onChange={this.onChange.bind(this)}
                                      error={this.state.errWidth}
                                    />
                                  </div>
                                }
                                {!(parseInt(this.state.product_group) === 0) &&
                                  <div className="col-span-2 sm:col-span-1">
                                    <TextInput
                                      name="height"
                                      type="number"
                                      label="Struc. Height (mm)"
                                      value={this.state.height}
                                      maxLength={45}
                                      asterix={true}
                                      onChange={this.onChange.bind(this)}
                                      error={this.state.errHeight}
                                    />
                                  </div>
                                }

                                {!(parseInt(this.state.product_group) === 0) &&
                                  <div className="col-span-2 sm:col-span-1">
                                    <TextInput
                                      name="frame_width"
                                      label="Frame Width (mm)"
                                      type="number"
                                      value={parseFloat(this.state.frame_width)}
                                      maxLength={45}
                                      onChange={this.onChange.bind(this)}
                                      error={this.state.errFrameWidth}
                                    />
                                  </div>
                                }
                                {!(parseInt(this.state.product_group) === 0) &&
                                  <div className="col-span-2 sm:col-span-1">
                                    <TextInput
                                      name="frame_height"
                                      type="number"
                                      label="Frame Height (mm)"
                                      value={parseFloat(this.state.frame_height)}
                                      maxLength={45}
                                      onChange={this.onChange.bind(this)}
                                      error={this.state.errFrameHeight}
                                    />
                                  </div>
                                }

                                {!(parseInt(this.state.product_group) === 0) &&
                                  <div className="col-span-2 sm:col-span-1">
                                    <SelectBox
                                      name="color"
                                      label="Colour"
                                      placeholder="Select..."
                                      value={this.state.color}
                                      maxLength={45}
                                      asterix={true}
                                      options={this.state.colors}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </div>
                                }

                                {!(parseInt(this.state.product_group) === 0) &&
                                  <div className="col-span-2 sm:col-span-1">
                                    {this.state.color &&
                                      <div className="item-color" style={{ backgroundColor: `#${this.state.colors.find(c => c.value === this.state.color).hex}` }}></div>
                                    }
                                  </div>
                                }

                              </div>
                            </div>

                            <div className="mt-5 md:mt-0 md:col-span-2 px-4 py-5">
                              <div className="grid grid-cols-1 gap-6">

                                {!(parseInt(this.state.product_group) === 0) &&
                                  <div>
                                    <SwitchButton
                                      label=""
                                      name="selectedOnly"
                                      text="Only show selected items"
                                      value={this.state.selectedOnly}
                                      onChange={this.toggleSelectedOnly.bind(this)}
                                    />
                                  </div>
                                }

                                {!(parseInt(this.state.product_group) === 0) &&
                                  <table className="min-w-full divide-y divide-gray-200">
                                    <thead>
                                      <tr>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="10%">
                                          Sku
                                        </th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="80%">
                                          Item
                                        </th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="10%">
                                          Qty
                                        </th>
                                        <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="10%">
                                          Price
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                      {this.state.products.filter(c => (!this.state.selectedOnly || parseInt(c.quantity) > 0) && (c.name.toLowerCase().includes(this.state.keyword.toLowerCase()) || c.sku.toLowerCase().includes(this.state.keyword.toLowerCase()))).map((item, index) => {
                                        var disabled = false;
                                        if (item.width_from && parseInt(item.width_from) > 0 && parseInt(item.width_from) > parseInt(this.state.width)) {
                                          disabled = true;
                                        }
                                        if (item.width_to && parseInt(item.width_to) > 0 && parseInt(item.width_to) < parseInt(this.state.width)) {
                                          disabled = true;
                                        }
                                        if (item.height_from && parseInt(item.height_from) > 0 && parseInt(item.height_from) > parseInt(this.state.height)) {
                                          disabled = true;
                                        }
                                        if (item.width_to && parseInt(item.height_to) > 0 && parseInt(item.height_to) < parseInt(this.state.height)) {
                                          disabled = true;
                                        }
                                        return (
                                          <tr key={index}>
                                            <td className="px-6 py-4 whitespace-no-wrap">
                                              <div className="text-sm leading-5 text-cool-gray-500">
                                                {item.sku}
                                              </div>
                                            </td>
                                            <td className="px-6 py-4">
                                              <div className={`${disabled ? 'text-gray-200' : 'text-gray-900'} text-sm leading-5 text-left`}>
                                                {item.name}
                                              </div>
                                              {item.notes &&
                                                <div className={`${disabled ? 'text-gray-200' : 'text-gray-500'} text-xs`}>
                                                  {item.notes}
                                                </div>
                                              }
                                              {(parseInt(item.width_from) > 0 || parseInt(item.width_to) > 0 || parseInt(item.height_from) > 0 || parseInt(item.height_to) > 0) &&
                                                <div className={`${disabled ? 'text-gray-200' : 'text-gray-500'} text-xs`}>
                                                  Width Range: {item.width_from}mm - {item.width_to}mm, Height Range: {item.height_from}mm - {item.height_to}mm
                                                </div>
                                              }
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap">
                                              <div className="text-sm leading-5 text-cool-gray-500">
                                                <input type="number" disabled={disabled} className={disabled ? 'input-qty-disabled' : 'input-qty'} value={item.quantity} min={item.min_items} max={item.max_items === 0 ? 99 : item.max_items} onChange={(e) => this.setQty(e, item.id)} />
                                              </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap">
                                              <div className={`${disabled ? 'text-gray-200' : 'text-gray-500'} text-sm leading-5`}>
                                                &pound;{item.price.toFixed(2)}
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      }
                                      )}
                                    </tbody>
                                  </table>
                                }


                              </div>
                            </div>


                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-4 gap-4 bg-gray-50 quote-footer">
                        <div className="col-span-2">
                          <div className="px-4 py-3 text-left sm:px-6">
                            {/*
                            <SubmitButton
                              text="Save Configuration"
                              display={'secondary'}
                              onClick={() => this.saveConfiguration()}
                            />
                              */}
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="px-4 py-3 text-right sm:px-6">
                            <SubmitButton
                              text="Save"
                              onClick={() => this.saveItem(true)}
                            />
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

            }

          </main>
        </div>
      </div>
    )

  }

}

QuotePage.propTypes = {
  firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    firstName: state.account.firstName,
  }
};

export default connect(mapStateToProps)(QuotePage);