import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserAPI from '../../api/UserAPI';

class LoginPage extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            failed: false,
        }
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {
    }

    onChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    handleLogin() {

        this.setState({
            isLoading: true
        });

        let payload = {
            email: this.state.email,
            password: this.state.password,
        };

        console.log(payload);

        UserAPI.auth(payload, (data) => {
            console.log("auth()", data);
            if (data) {
                if (data._id) {
                    localStorage.setItem('hmgUserData', JSON.stringify(data));
                    this.props.history.push('/');
                } else {
                    this.setState({
                        failed: true,
                        isLoading: false
                    });
                }
            } else {
                this.setState({
                    failed: true,
                    isLoading: false
                });
            }
        });
    }

    render() {

        return (
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div className="sm:mx-auto sm:w-full sm:max-w-md">
    <img className="mx-auto h-20 w-auto" src="/assets/img/logo-trans-black.png" alt="Workflow" />
    <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
      Sign in to your account
    </h2>
  </div>

  <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div>
          <label for="email" className="block text-sm font-medium leading-5 text-gray-700">
            Email address
          </label>
          <div className="mt-1 rounded-md shadow-sm">
            <input id="email" autoComplete={false} type="email" name="email" value={this.state.email} onChange={this.onChange.bind(this)} required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
          </div>
        </div>

        <div className="mt-6">
          <label for="password" className="block text-sm font-medium leading-5 text-gray-700">
            Password
          </label>
          <div className="mt-1 rounded-md shadow-sm">
            <input id="password" autoComplete={false} type="password" name="password" value={this.state.password} onChange={this.onChange.bind(this)} required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
          </div>
        </div>

        <div className="mt-6 flex items-center justify-between">
          <div className="flex items-center">
            <input id="remember_me" type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
            <label for="remember_me" className="ml-2 block text-sm leading-5 text-gray-900">
              Remember me
            </label>
          </div>

          <div className="text-sm leading-5">
            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
              Forgot your password?
            </a>
          </div>
        </div>

        {this.state.failed &&
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-6">
            <div className="flex">
                <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
                </div>
                <div className="ml-3">
                <p className="text-sm leading-5 text-yellow-700">
                    Invalid username/password.
                </p>
                </div>
            </div>
        </div>
        }

        <div className="mt-6">
          <span className="block w-full rounded-md shadow-sm">
            <button type="submit" onClick={this.handleLogin} className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
              Sign in
            </button>
          </span>
        </div>

    </div>
  </div>
</div>
          
        )

    }

}

LoginPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(LoginPage);