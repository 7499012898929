import React from 'react';

class MetricNumber extends React.Component {

    render() {
        return (
            <div className="metric-number">
                {this.props.value}
                <span>{this.props.label}</span>
            </div>
        )
    }

};

export default MetricNumber;