import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import WebsiteAPI from '../../api/WebsiteAPI';

class WebsitesPage extends Component {

  constructor() {
    super();
    this.state = {
      keyword: '',
      websites: [],
    }

  }

  componentDidMount() {
    WebsiteAPI.getWebsites((data) => {
      this.setState({
        websites: data
      })
    });
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  render() {

    return (
      <div className="h-screen flex overflow-hidden bg-cool-gray-100">

        <Menu {...this.props} />
        <MobileMenu {...this.props} />

        <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">

          <Header {...this.props} />

          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

            {/* begin page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                            Websites
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <span className="shadow-sm rounded-md">
                      <a href="/websites/edit/new" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline-teal focus:border-teal-700 active:bg-teal-700 transition duration-150 ease-in-out">
                        Create new website
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* end page header */}

            <div className="mt-8">
              {/* begin content block */}

              <div className="hidden sm:block">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex flex-col mt-2">
                    <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                      <table className="min-w-full divide-y divide-cool-gray-200">
                        <thead>
                          <tr>
                            <th width="40%" className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                              Domain
                            </th>
                            <th width="40%" className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                              Location
                            </th>
                            <th width="20%" className="hidden px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider md:block">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-cool-gray-200">
                          {this.state.websites.length === 0 ?
                            <tr className="bg-white">
                              <td colSpan="2" className="px-6 py-4 text-center whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                No websites have been added.
                              </td>
                            </tr>
                            :
                            this.state.websites.map((item, index) =>
                              <tr key={index} className="bg-white">
                                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-900">
                                  <div className="flex">
                                    <a href={`/websites/${item._id}`} className="group inline-flex space-x-2 truncate text-sm leading-5">
                                      <svg className="flex-shrink-0 h-5 w-5 text-cool-gray-400 group-hover:text-cool-gray-500 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                      </svg>
                                      <p className="text-cool-gray-500 truncate group-hover:text-cool-gray-900 transition ease-in-out duration-150">
                                        {item.domain}
                                      </p>
                                    </a>
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-900">
                                  {item.location}
                                </td>
                                <td className="px-6 py-4 text-left whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                  ACTIVE
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                      <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-cool-gray-200 sm:px-6">
                        <div className="hidden sm:block">
                          <p className="text-sm leading-5 text-cool-gray-700 span-line">
                            Showing
                            <span className="font-medium">1</span>
                            to
                            <span className="font-medium">10</span>
                            of
                            <span className="font-medium">20</span>
                            results
                          </p>
                        </div>
                        <div className="flex-1 flex justify-between sm:justify-end">
                          <a href="#" className="relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition ease-in-out duration-150">
                            Previous
                          </a>
                          <a href="#" className="ml-3 relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition ease-in-out duration-150">
                            Next
                          </a>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>

              {/* end content block */}
            </div>
          </main>
        </div>
      </div>
    )

  }

}

WebsitesPage.propTypes = {
  firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    firstName: state.account.firstName,
  }
};

export default connect(mapStateToProps)(WebsitesPage);