import apiRequest from '.';
import APIUtils from './APIUtils';

class TaskAPI {

    getTasks(options = {}, cb) {
        let url = "task?1=1";
        if (options.company) {
            url += `&company=${options.company}`;
        }
        if (options.contact) {
            url += `&contact=${options.contact}`;
        }
        if (options.assigned) {
            url += `&assigned=${options.assigned}`;
        }
        if (options.complete) {
            url += `&complete=${options.complete}`;
        }
        if (options.quote) {
            url += `&quote=${options.quote}`;
        }
        if (options.today) {
            url += `&today=${options.today}`;
        }
        if (options.week) {
            url += `&week=${options.week}`;
        }

        console.log(url);

        apiRequest({ endpoint: url }, cb);
    }

    addTask(payload, cb) {
        apiRequest({ endpoint: `task`, method: 'POST', payload }, cb);
    }

    completeTask(id, cb) {
        apiRequest({ endpoint: `task/${id}`, method: 'PUT', id }, cb);
    }

}

export default new TaskAPI();