export default(state = [], payload) => {
    switch(payload.type) {
        case 'update': {
            return Object.assign({}, state, payload.data);
        }

        default: {
            return state;
        }
    }
};