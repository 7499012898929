import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { signOut } from '../../actions/account';
import UserAPI from '../../api/UserAPI';
import NoteAPI from '../../api/NoteAPI';
import { TextInput, TextArea } from '../../components/TextInput';
import { SubmitButton } from '../../components/Button';
import moment from 'moment';

class Notes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
          id: this.props.id,
          author_id: '',
          notes: [],
          note: '',
        }

        this.addNote = this.addNote.bind(this);

    }

    componentDidMount() {
        UserAPI.getUserData((data) => {
            this.setState({
              author_id: data._id,
            }, () => {
                this.loadNotes();
            });
        })
    }

    loadNotes() {
        NoteAPI.getNotes(this.state.id, (data) => {
            console.log("notes", data);
            this.setState({
                notes: data,
                message: '',
            });
        })
    }

    addNote() {
        let payload = {
            ref: this.state.id,
            author_id: this.state.author_id,
            message: this.state.message
        }
        if (this.state.message !== '') {
            NoteAPI.addNote(payload, (data) => {
                this.loadNotes();
            })
        }
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    render() {
        return (

            <div>
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Notes
                        </h3>
                    </div>

                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="30%">
                                        Author
                                    </th>
                                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="70%">
                                        Note
                                    </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {this.state.notes.length === 0 &&
                                        <tr>
                                            <td colSpan="2" className="px-6 py-4 whitespace-no-wrap">
                                                <div className="text-sm leading-5 text-cool-gray-500 text-center">
                                                No notes added.
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    {this.state.notes.map((item, index) =>
                                    <tr key={index}>
                                    <td className="px-6 py-4 whitespace-no-wrap">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0 h-10 w-10">
                                                <img className="h-10 w-10 rounded-full" src={`/assets/img/users/${item.author_id._id}.jpg`} alt="" />
                                            </div>
                                            <div className="ml-4">
                                                <div className="text-sm leading-5 font-medium text-gray-900">
                                                    {item.author_id.firstname} {item.author_id.lastname}
                                                </div>
                                                <div className="text-sm leading-5 text-gray-500">
                                                    {moment(item.created).format("ddd D MMM YY - HH:mm")}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="text-sm leading-5 text-gray-900">
                                        {item.message}
                                        </div>
                                    </td>
                                    </tr>
                                    )}
                                </tbody>
                                </table>

                                <hr />

                                <div>
                                <dl>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm leading-5 font-medium text-gray-500">
                                    Add new note
                                    </dt>
                                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                        <TextArea 
                                            name="message"
                                            type="text"
                                            value={this.state.message}
                                            rows={3}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </dd>
                                </div>
                                </dl>
                                </div>

                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <SubmitButton
                                    text="Add note"
                                    onClick={this.addNote}
                                />
                            </div>

                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
            </div>

        )
    };

}

Notes.propTypes = {
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
        userName: state.account.userName,
    }
};

export default connect(mapStateToProps)(Notes);