import React, { Component } from 'react';
import sdLib from '../libs/sdLib';
import {Link} from 'react-router-dom';
import auth from '../libs/auth';

function LoggedInNav(props) {
    return (
    <div className="mai-wrapper">
        <nav className="navbar mai-sub-header">
            <div className="container">
                <nav className="navbar navbar-toggleable-sm">
                    <button type="button" data-toggle="collapse" data-target="#mai-navbar-collapse" aria-controls="#mai-navbar-collapse" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler hidden-md-up collapsed">
                    <div className="icon-bar"><span></span><span></span><span></span></div>
                    </button>
                    <div id="mai-navbar-collapse" className="navbar-collapse collapse mai-nav-tabs">
                    <ul className="nav navbar-nav">
                        <li className="nav-item parent open"><a href="#" role="button" aria-expanded="false" className="nav-link"><span className="icon s7-home"></span><span>Home</span></a>
                        <ul className="mai-nav-tabs-sub mai-sub-nav nav">
                            <li className="nav-item"><a href="/app" className="nav-link active"><span className="icon s7-monitor"></span><span className="name">Dashboard</span></a></li>
                        </ul>
                        </li>
                        <li className="nav-item parent"><a href="#" role="button" aria-expanded="false" className="nav-link"><span className="icon s7-rocket"></span><span>Traders</span></a>
                        <ul className="mai-nav-tabs-sub mai-sub-nav nav">
                            <li className="nav-item"><Link to={`/traders`} className="nav-link"><span className="name">All Traders</span></Link></li>
                            <li className="nav-item"><a href="/app/traders/blocked" className="nav-link"><span className="name">Blocked Traders</span></a></li>
                        </ul>
                        </li>
                        <li className="nav-item parent<?php if ($tab == 'spaces') { echo ' open'; } ?>"><a href="#" role="button" aria-expanded="false" className="nav-link"><span className="icon s7-diamond"></span><span>Spaces</span></a>
                        <ul className="mai-nav-tabs-sub mai-sub-nav nav">
                            <li className="nav-item"><a href="/app/spaces" className="nav-link active"><span className="icon s7-box2"></span><span className="name">All Spaces</span></a></li>
                        </ul>
                        </li>
                        <li className="nav-item parent"><a href="#" role="button" aria-expanded="false" className="nav-link"><span className="icon s7-rocket"></span><span>Bookings</span></a>
                        <ul className="mai-nav-tabs-sub mai-sub-nav nav">
                            <li className="nav-item"><a href="/app/bookings" className="nav-link active"><span className="name">All Bookings</span></a></li>
                        </ul>
                        </li>
                        <li className="nav-item parent"><a href="#" role="button" aria-expanded="false" className="nav-link"><span className="icon s7-display1"></span><span>Reports</span></a>
                        <ul className="mai-nav-tabs-sub mai-sub-nav nav">
                            <li className="nav-item"><a href="/app/reports/dots" className="nav-link active"><span className="icon s7-graph"></span><span className="name">Dots &amp; Slots</span></a></li>
                            <li className="nav-item"><a href="/app/reports/b2b" className="nav-link"><span className="icon s7-graph"></span><span className="name">Database (B2B)</span></a></li>
                            <li className="nav-item"><a href="/app/reports/b2c" className="nav-link"><span className="icon s7-graph"></span><span className="name">B2C</span></a></li>
                            <li className="nav-item"><a href="/app/reports/revenue" className="nav-link"><span className="icon s7-graph"></span><span className="name">Revenue</span></a></li>
                            <li className="nav-item"><a href="/app/reports/daily" className="nav-link"><span className="icon s7-graph"></span><span className="name">Daily Updates</span></a></li>
                        </ul>
                        </li>
                    </ul>
                    </div>
                </nav>
                <div className="search">
                    <input type="text" placeholder="Search..." name="q" id="q" /><span className="s7-search"></span>
                </div>
            </div>
        </nav>
    </div>
    )
}

function LoggedOutNav() {
    return (
        <div></div>
    )
}

export default class SiteNav extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        console.log(auth.auth.isAuthenticated);

        return (
            auth.auth.isAuthenticated
            ? <LoggedInNav {...this.props} />
            : <LoggedOutNav />
        )
    }

}