import apiRequest from '.';
import APIUtils from './APIUtils';

class UserAPI {

    isLoggedIn(cb) {
        if (localStorage.hasOwnProperty('userData')) {
            cb(true);
        } else {
            cb(false);
        }
    }

    getStats(cb) {
        apiRequest({ endpoint: `user/stats` }, cb);
    }

    getUserData(cb) {
        if (localStorage.hasOwnProperty('hmgUserData')) {
            cb(JSON.parse(localStorage.getItem('hmgUserData')));
        } else {
            cb(null);
        }
    }

    auth(payload, cb) {
        apiRequest({ endpoint: `auth`, method: 'POST', payload }, cb);
    }

    getUsers(cb) {
        let url = 'user';
        apiRequest({ endpoint: url }, cb);
    }

    getUser(id, cb) {
        apiRequest({ endpoint: `user/${id}` }, cb);
    }

    getStats(id, cb) {
        apiRequest({ endpoint: `user/stats/${id}` }, cb);
    }

    addUser(payload, cb) {
        apiRequest({ endpoint: `user`, method: 'POST', payload }, cb);
    }

    updUser(id, payload, cb) {
        apiRequest({ endpoint: `user/${id}`, method: 'PUT', payload }, cb);
    }

}

export default new UserAPI();