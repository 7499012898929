import APIUtils from "./APIUtils";

async function apiRequest({ endpoint = '', method = 'GET', payload = null }, cb) {
    const url = `${APIUtils.getBaseUrl()}${endpoint}`
    console.log(url);
    console.log(JSON.stringify(payload));
    APIUtils.getHeaders(async (headers) => {
        const response = await fetch(url, {
            method,
            headers,
            ...(payload != null ? { body: JSON.stringify(payload) } : {}),
        });
    
        const json = await response.json();
        cb(json);    
    });
}



export default apiRequest;