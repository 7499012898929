export const SET_SESSION = 'SET_SESSION';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_USER_DATA = 'SET_USER_DATA';

export const setSession = (payload) => ({
    type: SET_SESSION,
    payload,
});

export const setUserData = (payload) => ({
    type: SET_USER_DATA,
    payload,
});

export const signOut = () => ({
    type: SIGN_OUT,
})