import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import { SelectBox } from '../../components/SelectBox';
import UserAPI from '../../api/UserAPI';
import { Tasks } from '../../components/Tasks';
import { Notes } from '../../components/Notes';
import { Audit } from '../../components/Audit';

class UserPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      type: 'USER'
    }

  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser() {
    UserAPI.getUser(this.state.id, (data) => {
      this.setState({
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        password: data.password,
        type: data.type
      });
    })
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  render() {

    return (
      <div className="h-screen flex overflow-hidden bg-cool-gray-100">

        <Menu {...this.props} />
        <MobileMenu {...this.props} />

        <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">

          <Header {...this.props} />

          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

            {/* begin page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                            {this.state.firstname} {this.state.lastname}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <span className="shadow-sm rounded-md">
                      <a href={`/users/edit/${this.state.id}`} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline-teal focus:border-teal-700 active:bg-teal-700 transition duration-150 ease-in-out">
                        Edit
                          </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* end page header */}

            <div className="mt-8">
              {/* begin content block */}

              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">


                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="bg-white rounded-md shadow px-6 flex space-x-4 mb-8">
                    <li className="flex">
                      <div className="flex items-center">
                        <a href="/" className="text-gray-400 hover:text-gray-500">
                          <svg className="flex-shrink-0 h-5 w-5 transition duration-150 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                          </svg>
                        </a>
                        <span className="sr-only">Home</span>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center space-x-4">
                        <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <a href="/users" className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Users</a>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center space-x-4">
                        <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <a href={`/users/${this.state.id}`} className="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">{this.state.firstname} {this.state.lastname}</a>
                      </div>
                    </li>
                  </ol>
                </nav>


                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      User Information
    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                      User personal details and information.
    </p>
                  </div>
                  <div>
                    <dl>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Name
        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.firstname} {this.state.lastname}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Email address
        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          <a href={`mailto:${this.state.email}`}>{this.state.email}</a>
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Account type:
        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.type}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>

                <div className="hidden sm:block">
                  <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                  </div>
                </div>

                <Tasks title={`${this.state.firstname}'s Open Tasks`} assigned_tasks={this.state.id} complete={"false"} />

                <div className="hidden sm:block">
                  <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                  </div>
                </div>

                <Notes id={this.state.id} />

                <div className="hidden sm:block">
                  <div className="py-5">
                    <div className="border-t border-gray-200"></div>
                  </div>
                </div>

                <Audit user={this.state.id} />



              </div>

              {/* end content block */}
            </div>
          </main>
        </div>
      </div>
    )

  }

}

UserPage.propTypes = {
  firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    firstName: state.account.firstName,
  }
};

export default connect(mapStateToProps)(UserPage);