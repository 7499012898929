import React from 'react';

class SelectBox extends React.Component {

    onSelectChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.onChange(name, value);
    }

    render() {
        return (
            <div>
                {this.props.label ? <label for="country" className="block text-sm font-medium leading-5 text-gray-700">{this.props.label}{this.props.asterix ? <span className="asterix">*</span> : ''}</label> : ''}
                <select 
                    id={this.props.name}
                    className="mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    name={this.props.name} 
                    value={this.props.value} 
                    onChange={this.onSelectChange.bind(this)}
                    autoComplete="off"
                    disabled={this.props.disabled ? this.props.disabled : false}
                >
                    {this.props.placeholder &&
                    <option value="">{this.props.placeholder}</option>
                    }
                    {this.props.options.map((option, index) => 
                        <option key={index} value={option.value}>{option.label}</option>
                    )}
                </select>
                {this.props.error &&
                <div className="input-error">
                    <span>{this.props.error}</span>
                </div>
                }
            </div>
        )
    }

};

export default SelectBox;