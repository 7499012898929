import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import PricingAPI from '../../api/PricingAPI';

class PricingPage extends Component {

  constructor() {
    super();
    this.state = {
      keyword: '',
      pricing: [],
    }

  }

  componentDidMount() {
    this.loadPricing();
  }

  loadPricing() {
    PricingAPI.getProducts('', (data) => {
      console.log("getProducts()", data);
      this.setState({
        pricing: data
      });
    })
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  render() {

    return (
      <div className="h-screen flex overflow-hidden bg-cool-gray-100">

        <Menu {...this.props} />
        <MobileMenu {...this.props} />

        <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">

          <Header {...this.props} />

          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

            {/* begin page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                            Pricing
                              </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <span className="shadow-sm rounded-md">
                      <a href="/pricing/new" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline-teal focus:border-teal-700 active:bg-teal-700 transition duration-150 ease-in-out">
                        Create new group
                          </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* end page header */}

            <div className="mt-8">
              {/* begin content block */}
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">






                {this.state.pricing.sort((a, b) => a.order > b.order ? 1 : -1).map((item, index) =>



                  <div>
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                      <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap border-b border-gray-200 sm:flex-no-wrap px-4 py-5 sm:px-6">
                        <div className="ml-4 mt-4">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {item.name} <small>(Order: {item.order})</small>
                          </h3>
                        </div>
                        <div className="ml-4 mt-4 flex-shrink-0">
                          <span className="inline-flex rounded-md shadow-sm">
                            <a href={`/pricing/${item.id}`} className="mr-4 relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline-teal focus:border-teal-700 active:bg-teal-700">
                              Edit
                            </a>
                          </span>
                          <span className="inline-flex rounded-md shadow-sm">
                            <a href={`/pricing/${item.id}/new`} className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline-teal focus:border-teal-700 active:bg-teal-700">
                              Add Option
                            </a>
                          </span>
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="20%">
                                      Option Name
                                    </th>
                                    <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="20%">
                                      Price
                                    </th>
                                    <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="60%">
                                      Conditions
                                    </th>
                                    <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider" width="60%">
                                      &nbsp;
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {item.options.length === 0 &&
                                    <tr>
                                      <td colSpan="4" className="px-6 py-4 whitespace-no-wrap">
                                        <div className="text-sm leading-5 text-cool-gray-500 text-center">
                                          No options added.
                                                </div>
                                      </td>
                                    </tr>
                                  }
                                  {item.options.sort((a, b) => a.name > b.name ? 1 : -1).map((option, ix) =>
                                    <tr key={ix} className="bg-white">
                                      <td className="x-6 px-6 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                        <a href={`/pricing/${item.id}/${option.id}`} className="group inline-flex space-x-2 truncate text-sm leading-5">
                                          <p className="text-cool-gray-500 truncate group-hover:text-cool-gray-900 transition ease-in-out duration-150">
                                            {option.name}
                                          </p>
                                        </a>
                                      </td>
                                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                        &pound;{option.price.toFixed(2)}
                                      </td>
                                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                                        {option.rules.map((rule, irule) =>
                                          <div>{rule.group_name} {rule.condition} {rule.product_name}</div>
                                        )}
                                      </td>
                                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-right text-cool-gray-500">
                                        <a href={`/pricing/${item.id}/${option.id}`} className="group inline-flex space-x-2 truncate text-sm leading-5">
                                          <svg className="edit-item" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                          </svg>
                                        </a>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>

                            </div>

                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="hidden sm:block">
                      <div className="py-5">
                        <div className="border-t border-gray-200"></div>
                      </div>
                    </div>

                  </div>


                )}





              </div>
              {/* end content block */}
            </div>
          </main>
        </div>
      </div>
    )

  }

}

PricingPage.propTypes = {
  firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    firstName: state.account.firstName,
  }
};

export default connect(mapStateToProps)(PricingPage);