import React from 'react';
import PropTypes from 'prop-types';

class InputWithButton extends React.Component {

    constructor(props) {
        super(props);
        this.onKeyPress.bind(this);
        this.submitOnEnter.bind(this);
    }

    onFieldChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.onChange(name, value);
    }

    onKeyPress = (e) => {
        this.validateInput(e);
        this.submitOnEnter(e.key);
    }

    validateInput(e) {
        if (this.props.alhpaNumeric) {
            const re = /[0-9A-Za-z]+/g;
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }
    }

    submitOnEnter(key) {
        if (key === 'Enter') {
            this.props.onClick();
        }
    }

    render() {

        return (
            <div className="form-group">
                {this.props.label ? <label htmlFor={this.props.name}>{this.props.label}</label> : ''}
                <div className="input-group">
                    <input 
                        type="text"
                        id={this.props.name}
                        name={`${this.props.name}`}
                        placeholder={this.props.placeholder}
                        onChange={this.onFieldChange.bind(this)}
                        maxLength={this.props.maxLength ? this.props.maxLength : 200}
                        value={this.props.toUpper && this.props.value ? this.props.value.toUpperCase() : this.props.value}
                        className={this.props.class ? `${this.props.class} form-control` : 'form-control'}
                        onKeyPress={this.onKeyPress.bind(this)}
                        autoComplete={`auto-${this.props.name}`}
                    />
                    <div className="input-group-append">
                        <button className="btn inline-btn" onClick={this.props.onClick}>{this.props.text}</button>
                    </div>
                </div>
                {this.props.error ? <div className="invalid-error">{this.props.error}</div> : ''}
            </div>
        )

    }
};

InputWithButton.defaultProps = {
    submitOnEnter: true
}

InputWithButton.propTypes = {
    placeholder: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    error: PropTypes.string,
    onClick: PropTypes.func,
    name: PropTypes.string,
    class: PropTypes.string,
    value: PropTypes.string,
    toUpper: PropTypes.bool,
    label: PropTypes.string,
    alhpaNumeric: PropTypes.bool,
    submitOnEnter: PropTypes.bool
};

export default InputWithButton;