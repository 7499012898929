import React from 'react';

class TextArea extends React.Component {

    onFieldChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.onChange(name, value);
    }

    render() {
        return (
            <div>
                <label className="block text-sm font-medium leading-5 text-gray-700">{this.props.label}</label>
                <textarea
                    type="text"
                    name={`${this.props.name}`}
                    placeholder={this.props.placeholder}
                    onChange={this.onFieldChange.bind(this)}
                    maxLength={this.props.maxLength ? this.props.maxLength : 5000}
                    value={this.props.value}
                    className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    rows={this.props.rows ? this.props.rows : 3}
                />
            </div>
        )
    }

};

export default TextArea;