import React from 'react';

const SubmitButton = (props) => {
    const { label, text, className, disabled = false, onClick, display = "primary" } = props;
    return (

        <button
            className={`${display === 'secondary' ? 'py-2 px-4 border border-teal-600 text-sm leading-5 font-medium rounded-md text-teal-600 shadow-sm focus:outline-none focus:shadow-outline-teal bg-white hover:bg-teal-500 transition duration-150 ease-in-out' : 'py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white shadow-sm focus:outline-none focus:shadow-outline-teal bg-teal-600 hover:bg-teal-500 transition duration-150 ease-in-out'}`}
            onClick={onClick}>{text}</button>



    )
};

export default SubmitButton;