import React from 'react';

class SimpleValue extends React.Component {

    render() {
        return (
            <div className={this.props.total ? 'metric-simple-value metric-simple-total' : 'metric-simple-value'}>
                <div className="label">{this.props.title}</div>
                <div className="compare">{this.props.compare ? this.props.compare : ''}</div>
                <div className="value">{this.props.value}</div>
            </div>
        )
    }

};

export default SimpleValue;