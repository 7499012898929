import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { signOut } from '../../actions/account';
import UserAPI from '../../api/UserAPI';
import QuoteAPI from '../../api/QuoteAPI';
import { TextInput, TextArea } from '../../components/TextInput';
import { SubmitButton } from '../../components/Button';
import moment from 'moment';
import ContactAPI from '../../api/ContactAPI';

class Contacts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
          id: this.props.id,
          contacts: [],
        }

    }

    componentDidMount() {
        this.loadContacts();
    }

    loadContacts() {
        let params = {
            company: this.state.id,
        }
        ContactAPI.getContacts(params, (data) => {
            console.log("contacts", data);
            this.setState({
                contacts: data,
            });
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    render() {
        return (

            <div>
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">


                    <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap border-b border-gray-200 sm:flex-no-wrap px-4 py-5 sm:px-6">
                    <div className="ml-4 mt-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Contacts
                        </h3>
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0">
                    <span className="inline-flex rounded-md shadow-sm">
                        <a href={this.props.link} className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline-teal focus:border-teal-700 active:bg-teal-700">
                        Create new contact
                        </a>
                    </span>
                    </div>
                </div>

                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="20%">
                                        Name
                                    </th>
                                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="20%">
                                        Title
                                    </th>
                                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="20%">
                                        Email
                                    </th>
                                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="20%">
                                        Telephone
                                    </th>
                                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="20%">
                                        Mobile
                                    </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {this.state.contacts.length === 0 &&
                                        <tr>
                                            <td colSpan="5" className="px-6 py-4 whitespace-no-wrap">
                                                <div className="text-sm leading-5 text-cool-gray-500 text-center">
                                                No contacts added.
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    {this.state.contacts.map((item, index) =>
                                    <tr key={index}>
                                    <td className="px-6 py-4 whitespace-no-wrap">
                                        <div className="text-sm leading-5 text-gray-900">
                                            <a href={`/contacts/${item._id}`}>{item.firstname} {item.lastname}</a>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap">
                                        <div className="text-sm leading-5 text-gray-900">
                                        {item.title}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap">
                                        <div className="text-sm leading-5 text-gray-900">
                                        <a href={`mailto:${item.email}`}>{item.email}</a>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap">
                                        <div className="text-sm leading-5 text-gray-900">
                                        <a href={`tel:${item.telephone}`}>{item.telephone}</a>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap">
                                        <div className="text-sm leading-5 text-gray-900">
                                        <a href={`tel:${item.mobile}`}>{item.mobile}</a>
                                        </div>
                                    </td>
                                    </tr>
                                    )}
                                </tbody>
                                </table>

                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
            </div>

        )
    };

}

Contacts.propTypes = {
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
        userName: state.account.userName,
    }
};

export default connect(mapStateToProps)(Contacts);