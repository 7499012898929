import React from 'react';
import { withRouter, Link } from 'react-router-dom';

class MainLink extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Link to={this.props.url}><i className={`fas ${this.props.icon}`}></i>{this.props.title}{this.props.count ? <span>{this.props.count}</span> : ''}</Link>
        )
    }
};

export default withRouter(MainLink);