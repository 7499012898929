import apiRequest from '.';
import APIUtils from './APIUtils';

class QuoteAPI {

    getQuotes(params = {}, cb) {
        let url = "quote?1=1";
        if (params.company) {
            url += "&company=" + params.company;
        }
        if (params.contractor) {
            url += "&contractor=" + params.contractor;
        }
        if (params.status) {
            url += "&status=" + params.status;
        }

        console.log(url);

        apiRequest({ endpoint: url }, cb);
    }

    addQuote(payload, cb) {
        apiRequest({ endpoint: `quote`, method: 'POST', payload }, cb);
    }

    getQuote(id, cb) {
        apiRequest({ endpoint: `quote/${id}` }, cb);
    }

    getQuotePDF(id, cb) {
        apiRequest({ endpoint: `quote/pdf/${id}` }, cb);
    }

    updQuote(id, payload, cb) {
        apiRequest({ endpoint: `quote/${id}`, method: 'PUT', payload }, cb);
    }

    delQuote(id, cb) {
        apiRequest({ endpoint: `quote/${id}`, method: 'DELETE' }, cb);
    }

    getQuoteItems(id, cb) {
        let url = `quote/items/${id}`;
        apiRequest({ endpoint: url }, cb);
    }

    addQuoteItem(payload, cb) {
        apiRequest({ endpoint: `quote/item`, method: 'POST', payload }, cb);
    }

    copyItem(id, cb) {
        apiRequest({ endpoint: `quote/copy/${id}`, method: 'POST', id }, cb);
    }

    getQuoteItem(id, cb) {
        apiRequest({ endpoint: `quote/item/${id}` }, cb);
    }

    updQuoteItem(id, payload, cb) {
        apiRequest({ endpoint: `quote/item/${id}`, method: 'PUT', payload }, cb);
    }

    delQuoteItem(id, cb) {
        apiRequest({ endpoint: `quote/item/${id}`, method: 'DELETE' }, cb);
    }

}

export default new QuoteAPI();