import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Title } from '../../components/Title';
import { TodaysMetrics } from '../../components/MetricSidebar';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { Menu, MobileMenu } from '../../components/Menu';
import { Header } from '../../components/Header';
import CompanyAPI from '../../api/CompanyAPI';

class ContractorEditPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            name: '',
            email: '',
            line1: '',
            line2: '',
            town: '',
            county: '',
            postcode: '',
        }

        this.save = this.save.bind(this);

    }

    componentDidMount() {
        if (this.state.id !== 'new') {
            this.loadCompany();
        }
    }

    loadCompany() {
        CompanyAPI.getCompany(this.state.id, (data) => {
            this.setState({
                name: data.name,
                email: data.email,
                line1: data.line1,
                line2: data.line2,
                town: data.town,
                county: data.county,
                postcode: data.postcode,
            });
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    save() {

        let payload = {
            name: this.state.name,
            email: this.state.email,
            line1: this.state.line1,
            line2: this.state.line2,
            town: this.state.town,
            county: this.state.county,
            postcode: this.state.postcode,
            type: 'CONTRACTOR'
        }

        if (this.state.id === 'new') {
            CompanyAPI.addCompany(payload, (data) => {
                console.log("addCustomer()", data);
                this.props.history.push('/contractors');
            });
        } else {
            CompanyAPI.updCompany(this.state.id, payload, (data) => {
                console.log("addCustomer()", data);
                this.props.history.push('/contractors');
            });
        }
    }

    render() {

        return (
            <div className="h-screen flex overflow-hidden bg-cool-gray-100">

            <Menu {...this.props} />
            <MobileMenu {...this.props} />

            <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">
              
              <Header {...this.props} />

              <main className="flex-1 relative pb-8 z-0 overflow-y-auto">

                {/* begin page header */}
                <div className="bg-white shadow">
                  <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                    <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
                      <div className="flex-1 min-w-0">
                        <div className="flex items-center">
                          <div>
                            <div className="flex items-center">
                              <h1 className="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                                Contractor
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
                {/* end page header */}
          
                <div className="mt-8">
                {/* begin content block */}
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    
                    <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                            <p className="mt-1 text-sm leading-5 text-gray-600">
                            The customers personal details.
                            </p>
                        </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="name"
                                            type="text"
                                            label="Client name"
                                            value={this.state.name}
                                            maxLength={85}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="email"
                                            type="text"
                                            label="Email address"
                                            value={this.state.email}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <SubmitButton
                                    text="Save"
                                    onClick={this.save}
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className="hidden sm:block">
                        <div className="py-5">
                            <div className="border-t border-gray-200"></div>
                        </div>
                    </div>

                    <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Address Information</h3>
                            <p className="mt-1 text-sm leading-5 text-gray-600">
                            The customers postal address.
                            </p>
                        </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="line1"
                                            type="text"
                                            label="Address (line 1)"
                                            value={this.state.line1}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="line2"
                                            type="text"
                                            label="Address (line 2)"
                                            value={this.state.line2}
                                            maxLength={45}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="town"
                                            type="text"
                                            label="Town"
                                            value={this.state.town}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="county"
                                            type="text"
                                            label="County"
                                            value={this.state.county}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <TextInput 
                                            name="postcode"
                                            type="text"
                                            label="Post code"
                                            value={this.state.postcode}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                
                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <SubmitButton
                                    text="Save"
                                    onClick={this.save}
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>
                {/* end content block */}
                </div>

              </main>
            </div>
          </div>

        )

    }

}

ContractorEditPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(ContractorEditPage);