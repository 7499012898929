class APIUtils {

    getBaseUrl() {
        // TODO: Switch to environment variables - process.env.REACT_APP_API_URL
        return 'https://hmg-api.herokuapp.com/';
    }

    getHeaders(cb) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (localStorage.hasOwnProperty('hmgUserData')) {
            let user = JSON.parse(localStorage.getItem('hmgUserData'));
            headers.append('auth_token', user._id);
        }
        cb(headers);
    }

    postOptions(payload) {
       
        const options = {};
        options.method = "POST";
        options.payload = payload;
        options.headers = this.getHeaders();
        //options.body = JSON.stringify(payload);
        return options;
    }


}

export default new APIUtils();