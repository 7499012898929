import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { signOut } from '../../actions/account';
import UserAPI from '../../api/UserAPI';
import AuditAPI from '../../api/AuditAPI';
import { TextInput, TextArea } from '../../components/TextInput';
import { SubmitButton } from '../../components/Button';
import { SelectBox } from '../../components/SelectBox';
import moment from 'moment';

class Audit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
          log: [],
        }

    }

    componentDidMount() {
        this.loadLog();
    }

    loadLog() {
        let options = {};
        if (this.props.user) {
            options.user = this.props.user;
        }
        if (this.props.company) {
            options.company = this.props.company;
        }
        if (this.props.contact) {
            options.contact = this.props.contact;
        }

        console.log("audit options", options);

        AuditAPI.getAuditLog(options, (data) => {
            console.log("audit", data);
            this.setState({
                log: data,
            });
        })
    }

    render() {
        return (

            <div>
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Recent Activity
                        </h3>
                    </div>

                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="20%">
                                        Date
                                    </th>
                                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" width="80%">
                                        Activity
                                    </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {this.state.log.length === 0 &&
                                        <tr>
                                            <td colSpan="2" className="px-6 py-4 whitespace-no-wrap">
                                                <div className="text-sm leading-5 text-cool-gray-500 text-center">
                                                No activity recorded.
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    {this.state.log.map((item, index) =>
                                    <tr key={index}>
                                    <td className="px-6 py-4 whitespace-no-wrap">
                                        <div className={`text-sm leading-5 text-cool-gray-500`}>
                                            {moment(item.created).format("ddd D MMM YY - HH:mm")}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className={`text-sm leading-5 text-cool-gray-500`}>
                                            {item.message}
                                        </div>
                                    </td>
                                    </tr>
                                    )}
                                </tbody>
                                </table>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
            </div>

        )
    };

}

Audit.propTypes = {
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
        userName: state.account.userName,
    }
};

export default connect(mapStateToProps)(Audit);